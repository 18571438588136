import { useCallback, useEffect, useState } from 'react';
import Spinner from '../Spinner';

interface IFormState {
	name: string;
	email: string;
	phone: string;
	company: string;
	interest: string;
	content: string;
}

export default function FormMobile() {
	const [formState, setFormState] = useState<IFormState>({
		name: '',
		email: '',
		phone: '',
		company: '',
		interest: '',
		content: '',
	});

	const [isInputFocused, setIsInputFocused] = useState({
		name: false,
		email: false,
		phone: false,
		company: false,
		interest: false,
		message: false,
	});

	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const [csrfToken, setCsrfToken] = useState('');

	// useEffect(() => {
	// 	setCsrfToken(
	// 		document
	// 			.querySelector('meta[name="csrf-token"]')!
	// 			.getAttribute('content')!
	// 	);
	// }, []);

	useEffect(() => {
		const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
		if (csrfMetaTag) {
			setCsrfToken(csrfMetaTag.getAttribute('content') || '');
		}
	}, []);

	const handleSelectOption = (option: string) => {
		setFormState({ ...formState, interest: option });
		setIsDropdownOpen(false);
	};

	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
		},
		[]
	);

	// Select
	const options = [
		'Alocação',
		'Arquitetura de Software',
		'Assessment',
		'Consultoria',
		'Projetos',
		'Squad dedicada',
		'Trabalhe conosco',
		'Outros',
	];

	const [verifyInputs, setVerifyInputs] = useState(false);

	const [formattedPhone, setFormattedPhone] = useState<string>('');

	const formatPhone = (input: string) => {
		const number = input.replace(/\D/g, '');

		let formattedNumber = '';
		if (number.length >= 2) {
			formattedNumber += `(${number.substring(0, 2)}`;
		}
		if (number.length > 2) {
			formattedNumber += `) ${number.substring(2, 7)}`;
		}
		if (number.length > 7) {
			formattedNumber += `-${number.substring(7, 11)}`;
		}
		return formattedNumber.slice(0, 15);
	};

	const errors = {
		name: formState.name.length === 0 ? true : false,
		email:
			!/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(
				formState.email
			)
				? true
				: false,
		phone: !/^\(\d{2}\) \d{5}-\d{4}$/.test(formState.phone) ? true : false,
		company: formState.company.length === 0 ? true : false,
		interest: formState.interest.length === 0 ? true : false,
		message: formState.content.length < 10 ? true : false,
	};

	const [errorMessages, setErrorMessages] = useState({
		name: '',
		email: '',
		phone: '',
		interest: '',
		company: '',
		message: '',
	});

	const createErrorMessageEmail = () => {
		if (formState.email.length === 0) {
			return 'Campo obrigatório.';
		}
		if (
			!/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(
				formState.email
			)
		) {
			return 'E-mail inválido.';
		}
		return '';
	};

	const createErrorMessagePhone = () => {
		if (formState.phone.length === 0) {
			return 'Campo obrigatório.';
		}
		if (!/^\(\d{2}\) \d{5}-\d{4}$/.test(formState.phone)) {
			return 'Telefone inválido.';
		}
		return '';
	};

	const createErrorMessage = () => {
		if (formState.content.length === 0) {
			return 'Campo obrigatório.';
		}
		if (formState.content.length < 10) {
			return 'Mensagem deve ter no mínimo 10 caracteres.';
		}
		return '';
	};

	const [firstFeedbackMessage, setFirstFeedbackMessage] = useState('');
	const [secondFeedbackMessage, setSecondFeedbackMessage] = useState('');

	const [loading, setLoading] = useState(false);

	const handleClick = () => {
		setLoading(true);
		setFirstFeedbackMessage('');
		setSecondFeedbackMessage('');

		if (Object.values(errors).every((error) => !error)) {
			const formToSend = new FormData();

			formToSend.append('_token', csrfToken);
			formToSend.append('name', formState.name);
			formToSend.append('email', formState.email);
			formToSend.append('phone', formState.phone);
			formToSend.append('company', formState.company);
			formToSend.append('interest', formState.interest);
			formToSend.append('content', formState.content);

			// Enviar o formulário
			fetch('/send-email', {
				method: 'POST',
				body: formToSend,
			})
				.then((response) => response.json())
				.then((data) => {
					setLoading(false);
					setFirstFeedbackMessage('Formulário enviado!');
					setSecondFeedbackMessage('Retornaremos o contato em breve.');

					setFormState({
						...formState,
						name: '',
						email: '',
						phone: '',
						company: '',
						interest: '',
						content: '',
					});
					setFormattedPhone('');
					setVerifyInputs(false);
					errorMessages.name = '';
					errorMessages.email = '';
					errorMessages.phone = '';
					errorMessages.company = '';
					errorMessages.interest = '';
					errorMessages.message = '';
				})
				.catch((error) => {
					setLoading(false);
					setFirstFeedbackMessage('Falha ao enviar o formulário!');
					setSecondFeedbackMessage('Por favor, tente novamente.');
				});
		} else {
			setLoading(false);
			setVerifyInputs(true);
			setErrorMessages({
				name: errors.name ? 'Campo obrigatório.' : '',
				email: createErrorMessageEmail(),
				phone: createErrorMessagePhone(),
				company: errors.company ? 'Campo obrigatório.' : '',
				interest: errors.interest ? 'Campo obrigatório.' : '',
				message: createErrorMessage(),
			});
		}
	};

	// Estilização e animação do botão
	const [isHovered, setIsHovered] = useState(false);

	const buttonStyle: React.CSSProperties = {
		backgroundColor: isHovered ? '#380F43' : '#521262',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
		cursor: isHovered ? 'pointer' : 'default',
		overflow: 'hidden',
		width: '100%',
		height: '46px',
		color: '#FAFAFA',
		borderRadius: '4px',
		border: 0,
		alignSelf: 'stretch',
		paddingLeft: '16px',
		paddingRight: '16px',
		paddingTop: '12px',
		paddingBottom: '12px',
		letterSpacing: '0.1px',
		fontSize: '16px',
		lineHeight: '22.4px',
		fontWeight: 500,
		gap: '8px',
	};

	const spanStyle: React.CSSProperties = {
		paddingLeft: '16px',
		textAlign: 'center',
		marginLeft: isHovered ? '-10px' : '0',
		transition: 'margin-left 0.3s ease',
		zIndex: 1,
	};

	const arrowStyle: React.CSSProperties = {
		alignSelf: 'center',
		opacity: isHovered ? 1 : 0,
		transition: 'opacity 0.3s ease, right 0.3s ease',
	};

	return (
		<form
			target="/send-email"
			method="post"
			id="form-section"
			style={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: '#E4E4E7',
				paddingLeft: '5%',
				paddingRight: '5%',
				paddingTop: '48px',
				paddingBottom: '48px',
			}}
			onSubmit={handleSubmit}
			noValidate
		>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
				<div>
					<h3
						style={{
							color: '#521262',
							margin: 0,
							fontSize: '24px',
							lineHeight: '34px',
							fontWeight: 800,
							alignItems: 'flex-start',
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							style={{ verticalAlign: 'top', marginTop: 2 }}
						>
							<path d="M12 0H0V12H3.42857V3.42857H12V0Z" fill="#2A6173" />
						</svg>
						SAIBA MAIS
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							style={{ verticalAlign: 'top', marginTop: 22 }}
						>
							<path d="M0 12H12V0H8.57143V8.57143H0V12Z" fill="#2A6173" />
						</svg>
					</h3>
					<p
						style={{
							color: '#71717A',
							marginBottom: 0,
							fontSize: '16px',
							lineHeight: '22px',
							fontWeight: 400,
							marginTop: '5px',
						}}
					>
						Tire suas dúvidas e conecte-se conosco.
					</p>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 22,
						alignItems: 'stretch',
						textAlign: 'start',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<label htmlFor="name"></label>
						<input
							type="text"
							name="name"
							id="name"
							placeholder="Nome *"
							required
							autoComplete="off"
							value={formState.name}
							onChange={(e) =>
								setFormState({
									...formState,
									name: e.currentTarget?.value || '',
								})
							}
							style={{
								backgroundColor: 'transparent',
								color: '#18181B',
								fontSize: '18px',
								lineHeight: '18px',
								borderRadius: '4px',
								border:
									verifyInputs && errors.name
										? '2px solid #EF4444'
										: isInputFocused.name
										? '2px solid #521262'
										: '1px solid #71717A',
								padding: '12px',
								alignSelf: 'stretch',
								outline: 'none',
							}}
							onFocus={() => {
								setIsInputFocused({ ...isInputFocused, name: true });
							}}
							onBlur={(e) => {
								setIsInputFocused({ ...isInputFocused, name: false });
							}}
						/>
						<div
							style={{
								color: '#71717A',
								fontSize: '12px',
								position: 'absolute',
								marginTop: '50px',
							}}
						>
							{errors.name && errorMessages.name && (
								<span>{errorMessages.name}</span>
							)}
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<label htmlFor="email"></label>
						<input
							type="email"
							name="email"
							id="email"
							placeholder="E-mail *"
							required
							autoComplete="off"
							value={formState.email}
							onChange={(e) =>
								setFormState({
									...formState,
									email: e.currentTarget?.value || '',
								})
							}
							style={{
								backgroundColor: 'transparent',
								color: '#18181B',
								fontSize: '18px',
								lineHeight: '18px',
								borderRadius: '4px',
								border:
									verifyInputs && errors.email
										? '2px solid #EF4444'
										: isInputFocused.email
										? '2px solid #521262'
										: '1px solid #71717A',
								padding: '12px',
								alignSelf: 'stretch',
								outline: 'none',
							}}
							onFocus={() => {
								setIsInputFocused({ ...isInputFocused, email: true });
							}}
							onBlur={(e) => {
								setIsInputFocused({ ...isInputFocused, email: false });
							}}
						/>
						<div
							style={{
								color: '#71717A',
								fontSize: '12px',
								position: 'absolute',
								marginTop: '50px',
							}}
						>
							{errors.email && errorMessages.email && (
								<span>{errorMessages.email}</span>
							)}
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<label htmlFor="phone"></label>
						<input
							type="tel"
							name="phone"
							id="phone"
							placeholder="Telefone *"
							maxLength={15}
							required
							autoComplete="off"
							value={formattedPhone || formState.phone}
							style={{
								backgroundColor: 'transparent',
								color: '#18181B',
								fontSize: '18px',
								lineHeight: '18px',
								borderRadius: '4px',
								border:
									verifyInputs && errors.phone
										? '2px solid #EF4444'
										: isInputFocused.phone
										? '2px solid #521262'
										: '1px solid #71717A',
								padding: '12px',
								alignSelf: 'stretch',
								outline: 'none',
							}}
							onChange={(e) => {
								const inputPhone = e.currentTarget?.value || '';
								setFormState({
									...formState,
									phone: inputPhone,
								});
								setFormattedPhone(formatPhone(inputPhone));
							}}
							onFocus={() => {
								setIsInputFocused({ ...isInputFocused, phone: true });
							}}
							onBlur={(e) => {
								setIsInputFocused({ ...isInputFocused, phone: false });
							}}
						/>
						<div
							style={{
								color: '#71717A',
								fontSize: '12px',
								position: 'absolute',
								marginTop: '50px',
							}}
						>
							{errors.phone && errorMessages.phone && (
								<span>{errorMessages.phone}</span>
							)}
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<label htmlFor="company"></label>
						<input
							type="text"
							name="company"
							id="company"
							placeholder="Empresa *"
							required
							autoComplete="off"
							value={formState.company}
							style={{
								backgroundColor: 'transparent',
								color: '#18181B',
								fontSize: '18px',
								lineHeight: '18px',
								borderRadius: '4px',
								border:
									verifyInputs && errors.company
										? '2px solid #EF4444'
										: isInputFocused.company
										? '2px solid #521262'
										: '1px solid #71717A',
								padding: '12px',
								alignSelf: 'stretch',
								outline: 'none',
							}}
							onChange={(e) =>
								setFormState({
									...formState,
									company: e.currentTarget?.value || '',
								})
							}
							onFocus={() => {
								setIsInputFocused({ ...isInputFocused, company: true });
							}}
							onBlur={(e) => {
								setIsInputFocused({ ...isInputFocused, company: false });
							}}
						/>
						<div
							style={{
								color: '#71717A',
								fontSize: '12px',
								position: 'absolute',
								marginTop: '50px',
							}}
						>
							{errors.company && errorMessages.company && (
								<span>{errorMessages.company}</span>
							)}
						</div>
					</div>
					<div
						style={{
							position: 'relative',
							boxSizing: 'border-box',
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'row',
							border:
								verifyInputs && errors.interest
									? '2px solid #EF4444'
									: isInputFocused.interest
									? '2px solid #521262'
									: '1px solid #71717A',
							borderRadius: '4px',
							borderBottom: isDropdownOpen
								? '1px solid #71717A'
								: '1px solid #71717A',
						}}
						onClick={() => setIsDropdownOpen(!isDropdownOpen)}
						onFocus={() => {
							setIsInputFocused({ ...isInputFocused, interest: true });
						}}
						onBlur={(e) => {
							setIsInputFocused({ ...isInputFocused, interest: false });
						}}
						tabIndex={0}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'flex-start',
								cursor: 'pointer',
								backgroundColor: 'transparent',
								color: formState.interest === '' ? '#71717A' : '#18181B',
								fontSize: '18px',
								lineHeight: '18px',
								letterSpacing: '0.15px',
								padding: '12px',
								alignSelf: 'stretch',
								width: '100%',
								height: '48px',
								outline: 'none',
								boxSizing: 'border-box',
								margin: 0,
								appearance: 'none',
							}}
						>
							<span
								style={{
									paddingTop: '4px',
									width: '100%',
								}}
							>
								{formState.interest || 'Interesse *'}
							</span>
							{isDropdownOpen ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<mask
										id="mask0_1438_424"
										maskUnits="userSpaceOnUse"
										x="0"
										y="0"
										width="24"
										height="24"
									>
										<rect width="24" height="24" fill="#D9D9D9" />
									</mask>
									<g mask="url(#mask0_1438_424)">
										<path
											d="M12 10.775L8.10005 14.675C7.91672 14.8583 7.68338 14.95 7.40005 14.95C7.11672 14.95 6.88338 14.8583 6.70005 14.675C6.51672 14.4917 6.42505 14.2583 6.42505 13.975C6.42505 13.6917 6.51672 13.4583 6.70005 13.275L11.3 8.675C11.4 8.575 11.5084 8.50417 11.625 8.4625C11.7417 8.42083 11.8667 8.4 12 8.4C12.1334 8.4 12.2584 8.42083 12.375 8.4625C12.4917 8.50417 12.6 8.575 12.7 8.675L17.3 13.275C17.4834 13.4583 17.575 13.6917 17.575 13.975C17.575 14.2583 17.4834 14.4917 17.3 14.675C17.1167 14.8583 16.8834 14.95 16.6 14.95C16.3167 14.95 16.0834 14.8583 15.9 14.675L12 10.775Z"
											fill={formState.interest === '' ? '#71717A' : '#18181B'}
										/>
									</g>
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<mask
										id="mask0_1438_394"
										maskUnits="userSpaceOnUse"
										x="0"
										y="0"
										width="24"
										height="24"
									>
										<rect width="24" height="24" fill="#D9D9D9" />
									</mask>
									<g mask="url(#mask0_1438_394)">
										<path
											d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z"
											fill={formState.interest === '' ? '#71717A' : '#18181B'}
										/>
									</g>
								</svg>
							)}
						</div>
						{isDropdownOpen && (
							<div
								style={{
									position: 'absolute',
									top: '100%',
									left: '0',
									width: '100%',
									overflowY: 'auto',
									height: '160px',
									backgroundColor: '#E4E4E7',
									zIndex: 2,
									textAlign: 'start',
									marginTop: '1px',
									border: '1px solid #71717A',
									borderBottom: '1px solid #71717A',
									borderRight: '1px solid #71717A',
									borderLeft: '1px solid #71717A',
									borderRadius: '4px',
									padding: '0',
								}}
							>
								<ul
									style={{
										listStyleType: 'none',
										padding: '0',
										marginTop: '0',
										marginBottom: '0',
									}}
								>
									{options.map((option) => (
										<li
											key={option}
											style={{
												cursor: 'pointer',
												padding: '10px',
												color: '#71717A',
											}}
											onClick={() => handleSelectOption(option)}
											onMouseEnter={(e) =>
												(e.currentTarget.style.backgroundColor = '#DDC6DD') &&
												(e.currentTarget.style.color = '#18181B')
											}
											onMouseLeave={(e) =>
												(e.currentTarget.style.backgroundColor =
													'transparent') &&
												(e.currentTarget.style.color = '#71717A')
											}
										>
											{option}
										</li>
									))}
								</ul>
							</div>
						)}
						<div
							style={{
								color: '#71717A',
								fontSize: '12px',
								position: 'absolute',
								marginTop: '67px',
							}}
						>
							{errors.interest && errorMessages.interest && (
								<span>{errorMessages.interest}</span>
							)}
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '5px',
						}}
					>
						<textarea
							name="message"
							id="message"
							placeholder="Mensagem *"
							required
							autoComplete="off"
							value={formState.content}
							style={{
								backgroundColor: 'transparent',
								color: '#18181B',
								fontSize: '18px',
								lineHeight: '18px',
								borderRadius: '4px',
								border:
									verifyInputs && errors.message
										? '2px solid #EF4444'
										: isInputFocused.message
										? '2px solid #521262'
										: '1px solid #71717A',
								padding: '12px',
								boxSizing: 'border-box',
								outline: 'none',
								height: '153px',
								alignSelf: 'flex-start',
								width: '100%',
								fontFamily: 'Roboto, sans-serif',
							}}
							onChange={(e) =>
								setFormState({
									...formState,
									content: e.currentTarget?.value || '',
								})
							}
							onFocus={() => {
								setIsInputFocused({ ...isInputFocused, message: true });
							}}
							onBlur={(e) => {
								setIsInputFocused({ ...isInputFocused, message: false });
							}}
						></textarea>
						<div
							style={{
								color: '#71717A',
								fontSize: '12px',
								position: 'absolute',
								marginTop: '154px',
							}}
						>
							{errors.message && errorMessages.message && (
								<span>{errorMessages.message}</span>
							)}
						</div>
					</div>
					<button
						type="submit"
						style={buttonStyle}
						onClick={handleClick}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						{loading ? (
							<Spinner size={25} color="#FAFAFA" />
						) : (
							<>
								<span style={spanStyle}>Enviar</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="13"
									height="18"
									viewBox="0 0 13 18"
									fill="none"
									style={arrowStyle}
								>
									<path
										d="M7.85 9L0.949997 2.1L3.05 0L12.05 9L3.05 18L0.949997 15.9L7.85 9Z"
										fill="#FAFAFA"
									/>
								</svg>
							</>
						)}
					</button>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								textAlign: 'center',
							}}
						>
							<div
								style={{
									color: '#521262',
									fontSize: '18px',
									width: '100%',
									letterSpacing: '0.15px',
									lineHeight: '140%',
									fontWeight: 700,
									marginBottom: '0px',
								}}
							>
								{firstFeedbackMessage}
							</div>

							<div
								style={{
									color: '#71717A',
									fontSize: '18px',
									width: '100%',
									marginTop: '0px',
									letterSpacing: '0.15px',
									lineHeight: '140%',
								}}
							>
								{secondFeedbackMessage}
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}
