import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useSetDevice from '../../hooks/useSetDevice';
import imgAllocation from '../../assets/images/img-carousel-allocation.jpg';
import imgArchitecture from '../../assets/images/img-carousel-architecture.jpg';
import imgAssessment from '../../assets/images/img-carousel-assessment.jpg';
import imgConsultancy from '../../assets/images/img-carousel-consultancy.jpg';
import imgProjects from '../../assets/images/img-carousel-projects.jpg';
import imgSquad from '../../assets/images/img-carousel-squad.jpg';

interface Slide {
	image: string;
	alt: string;
	text: string;
	title: string;
}

const Carousel: React.FC = () => {
	const deviceType = useSetDevice();

	const cardHeight = () => {
		if (deviceType === 'mobile') {
			return { height: '20%' };
		} else if (deviceType === 'tablet') {
			return { height: '410px' };
		} else if (deviceType === 'desktop') {
			return { height: '22%' };
		}
	};

	const [slidesToShow, setSlidesToShow] = useState(3);

	useEffect(() => {
		const calculateSlidesToShow = () => {
			const totalCards = 6;
			const smallScreenBreakpoint = 374;
			const mobileBreakpoint = 615;
			const tabletBreakpoint = 768;

			if (window.innerWidth < smallScreenBreakpoint) {
				setSlidesToShow(1);
			} else if (window.innerWidth < mobileBreakpoint) {
				setSlidesToShow(1.2);
			} else if (
				window.innerWidth > mobileBreakpoint &&
				window.innerWidth < tabletBreakpoint
			) {
				setSlidesToShow(2);
			} else if (
				window.innerWidth >= tabletBreakpoint &&
				window.innerWidth < 920
			) {
				setSlidesToShow(2.3);
			} else {
				const cardWidth = 315;
				const maxSlidesToShow = Math.floor(window.innerWidth / cardWidth);

				setSlidesToShow(Math.min(maxSlidesToShow, totalCards));
			}
		};

		window.addEventListener('resize', calculateSlidesToShow);
		calculateSlidesToShow();

		return () => {
			window.removeEventListener('resize', calculateSlidesToShow);
		};
	}, [deviceType]);

	const smallScreenStyle = {
		minWidth: '15%',
		heigth: '15%',
		fontSize: '12px',
	};

	const slides: Slide[] = [
		{
			image: imgAllocation,
			alt: 'Allocation',
			title: 'ALOCAÇÃO',
			text: 'Comprometimento em identificar e alocar o profissional ideal para suas necessidades. Trabalhamos em colaboração para contratações alinhadas com seus objetivos.',
		},
		{
			image: imgArchitecture,
			alt: 'Architecture',
			title: 'ARQUITETURA',
			text: 'Orientação e suporte em arquitetura de software para garantir soluções eficientes, escaláveis, seguras e sustentáveis, alinhadas com suas metas e requisitos.',
		},
		{
			image: imgAssessment,
			alt: 'Assessment',
			title: 'ASSESSMENT',
			text: 'Análises abrangentes que orientam estratégias e otimizam processos tecnológicos, fortalecendo a excelência operacional e alinhando-os às melhores práticas do setor.',
		},
		{
			image: imgConsultancy,
			alt: 'Consultancy',
			title: 'CONSULTORIA',
			text: 'Um time de especialistas pronto para oferecer orientação estratégica, insights de mercado e soluções personalizadas para impulsionar o crescimento da sua empresa.',
		},
		{
			image: imgProjects,
			alt: 'Projects',
			title: 'PROJETOS',
			text: 'Trabalhamos com 3 modalidades de projetos: escopo fechado, escopo aberto e a modalidade on-demand, focada em pequenas entregas com detalhamento limitado.',
		},
		{
			image: imgSquad,
			alt: 'Squad',
			title: 'SQUAD DEDICADA',
			text: 'A combinação essencial de velocidade, expertise profissional e flexibilidade para permitir uma escalabilidade rápida do seu projeto em um modelo altamente ágil.',
		},
	];

	const settings = {
		dots: false,
		arrows: deviceType === 'mobile' ? false : true,
		infinite: false,
		speed: 500,
		slidesToShow,
		slidesToScroll: 1,
	};

	return (
		<Slider {...settings}>
			{slides.map((slide, index) => (
				<div
					key={index}
					style={{
						marginBottom: '16px',
						marginRight: '16px',
					}}
				>
					<div
						className="carousel-card-container"
						style={{
							width: '100%',
							height: '100%',
							marginBottom: '10px',
							paddingTop: '5%',
						}}
					>
						<div
							className="carousel-card"
							style={{
								display: 'flex',
								flexDirection: 'column',
								boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.16)',
								marginRight: deviceType === 'desktop' ? '32px' : '5%',
								width: 'auto',
								height: '100%',
								minWidth: '265px',
								...cardHeight(),
								...(window.innerWidth <= 375 ? smallScreenStyle : {}),
								backgroundColor: '#E4E4E7',
								borderRadius: '8px',
							}}
						>
							<img
								src={slide.image}
								alt={slide.alt}
								style={{
									width: '100%',
									height: 'auto',
									objectFit: 'cover',
									borderRadius: '8px 8px 0px 0px',
								}}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									minHeight: '200px',
									maxHeight: '300px',
									paddingLeft: deviceType !== 'mobile' ? '10%' : '5%',
									paddingRight: deviceType !== 'mobile' ? '10%' : '5%',
								}}
							>
								<h3
									style={{
										marginTop: '16px',
										marginBottom: 0,
										fontSize: deviceType === 'mobile' ? '14px' : '18px',
									}}
								>
									{slide.title}
								</h3>
								<p
									style={{
										textAlign: 'left',
										margin: '10px 0',
										fontSize: '16px',
										color: '#71717A',
										overflow: 'hidden',
										lineHeight: '120%',
									}}
								>
									{slide.text}
								</p>
							</div>
						</div>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default Carousel;
