import logoPath from '../../assets/images/logo-path.svg';
import logoPathMobile from '../../assets/images/logo-path-mobile.svg';
import iconMenu from '../../assets/images/icon-menu.svg';
import iconCloseMenu from '../../assets/images/icon-close-menu.svg';
import React, { useState } from 'react';

interface IHeader {
	activeComponent: string | null;
	deviceType: string;
}

const Header: React.FC<IHeader> = ({ activeComponent, deviceType }) => {
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

	const toggleMenu = () => {
		setIsOpenMenu(!isOpenMenu);
	};

	const scrollToSection = (sectionId: string) => {
		const targetElement = document.getElementById(sectionId);

		if (targetElement) {
			window.scrollTo({
				top: targetElement.offsetTop,
				behavior: 'smooth',
			});
		}
	};

	const renderSvg = (componentName: string) => {
		if (
			!activeComponent &&
			(componentName === 'Header' || componentName === 'ImageContainer')
		) {
			return (
				<>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="7"
						height="8"
						viewBox="0 0 7 8"
						fill="none"
						style={{
							verticalAlign: 'top',
							marginBottom: '1px',
							marginRight: '2px',
						}}
					>
						<path d="M7 0.5H0V7.5H2V2.5H7V0.5Z" fill="#FAFAFA" />
					</svg>
					INÍCIO
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="7"
						height="8"
						viewBox="0 0 7 8"
						fill="none"
						style={{
							verticalAlign: 'bottom',
							marginBottom: '0px',
							marginLeft: '1px',
						}}
					>
						<path d="M0 7.5H7V0.5H5V5.5H0V7.5Z" fill="#FAFAFA" />
					</svg>
				</>
			);
		}

		if (componentName === activeComponent) {
			return (
				<>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="7"
						height="8"
						viewBox="0 0 7 8"
						fill="none"
						style={{
							verticalAlign: 'top',
							marginBottom: '1px',
							marginRight: '2px',
						}}
					>
						<path d="M7 0.5H0V7.5H2V2.5H7V0.5Z" fill="#FAFAFA" />
					</svg>
					{getText(componentName)}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="7"
						height="8"
						viewBox="0 0 7 8"
						fill="none"
						style={{
							verticalAlign: 'bottom',
							marginBottom: '0px',
							marginLeft: '1px',
						}}
					>
						<path d="M0 7.5H7V0.5H5V5.5H0V7.5Z" fill="#FAFAFA" />
					</svg>
				</>
			);
		} else {
			return <>{getText(componentName)}</>;
		}
	};

	const getText = (componentName: string) => {
		switch (componentName) {
			case 'Header':
			case 'ImageContainer':
				return 'INÍCIO';
			case 'ContainerCards':
			case 'ComponentCards':
				return 'SOBRE NÓS';
			case 'BackgroundSolutions':
			case 'ContainerCarouselSolutions':
				return 'SOLUÇÕES';
			case 'ComponentProduct':
				return 'METODOLOGIA';
			case 'Form':
			case 'Footer':
				return 'CONTATO';
			default:
				return '';
		}
	};

	const fontText = () => {
		if (deviceType === 'tablet') {
			return { fontSize: '14px', lineHeight: '22px' };
		} else {
			return { fontSize: '16px', lineHeight: '25px' };
		}
	};

	return (
		<div
			id="header-section"
			style={{
				paddingLeft: deviceType !== 'mobile' ? '8%' : '5%',
				paddingRight: isOpenMenu ? 0 : deviceType !== 'mobile' ? '8%' : '5%',
				zIndex: 3,
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				justifyContent: 'space-between',
				flexDirection: 'row',
				alignItems: 'center',
				backgroundColor: 'rgba(56, 15, 67, 0.85)',
				height: 80,
				display: 'flex',
			}}
		>
			{deviceType !== 'desktop' ? (
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<img
						src={logoPathMobile}
						height={32}
						width={51}
						alt="logoPath"
						draggable={false}
						style={{
							pointerEvents: 'none',
						}}
					/>
					{deviceType === 'mobile' && (
						<div
							style={{
								width: '281px',
								height: 'auto',
							}}
						>
							<button
								onClick={toggleMenu}
								style={{
									backgroundColor: 'transparent',
									border: 'none',
									cursor: 'pointer',
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'flex-end',
									width: '100%',
									margin: 0,
									padding: 0,
								}}
							>
								{isOpenMenu ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
											height: '80px',
										}}
									>
										<div
											style={{
												backgroundColor: '#380F43',
												height: '80px',
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'flex-end',
											}}
										>
											<img
												src={iconCloseMenu}
												alt="icon close menu"
												style={{
													width: 'auto',
													height: '100%',
													pointerEvents: 'none',
												}}
											/>
										</div>
										<div
											style={{
												position: 'fixed',
												top: '80px',
												backgroundColor: '#E4E4E7',
												padding: '16px 16px 0px 16px',
												width: '100%',
												height: '100vh',
												textAlign: 'start',
												boxSizing: 'border-box',
												border: 0,
											}}
										>
											<p
												style={{
													backgroundColor: 'transparent',
													border: 'none',
													color: '#18181B',
													alignSelf: 'center',
													paddingTop: '10px',
													margin: 0,
													fontSize: fontText().fontSize,
													lineHeight: fontText().lineHeight,
													fontWeight: 500,
													cursor: 'pointer',
												}}
												id="inicio"
												onClick={() => scrollToSection('header-section')}
											>
												INÍCIO
											</p>
											<div
												style={{
													borderBottom: '1px solid #D4D4D8',
													marginTop: 10,
													marginBottom: 10,
												}}
											/>
											<p
												style={{
													backgroundColor: 'transparent',
													border: 'none',
													color: '#18181B',
													alignSelf: 'center',
													margin: 0,
													fontSize: fontText().fontSize,
													lineHeight: fontText().lineHeight,
													fontWeight: 500,
													cursor: 'pointer',
												}}
												id="sobre-nos"
												onClick={() =>
													scrollToSection('container-cards-section')
												}
											>
												SOBRE NÓS
											</p>
											<div
												style={{
													borderBottom: '1px solid #D4D4D8',
													marginTop: 10,
													marginBottom: 10,
												}}
											/>
											<p
												style={{
													backgroundColor: 'transparent',
													border: 'none',
													color: '#18181B',
													alignSelf: 'center',
													margin: 0,
													fontSize: fontText().fontSize,
													lineHeight: fontText().lineHeight,
													fontWeight: 500,
													cursor: 'pointer',
												}}
												id="solucoes"
												onClick={() =>
													scrollToSection('background-solutions-section')
												}
											>
												SOLUÇÕES
											</p>
											<div
												style={{
													borderBottom: '1px solid #D4D4D8',
													marginTop: 10,
													marginBottom: 10,
												}}
											/>
											<p
												style={{
													backgroundColor: 'transparent',
													border: 'none',
													color: '#18181B',
													alignSelf: 'center',
													margin: 0,
													fontSize: fontText().fontSize,
													lineHeight: fontText().lineHeight,
													fontWeight: 500,
													cursor: 'pointer',
												}}
												id="metodologia"
												onClick={() =>
													scrollToSection('component-product-section')
												}
											>
												METODOLOGIA
											</p>
											<div
												style={{
													borderBottom: '1px solid #D4D4D8',
													marginTop: 10,
													marginBottom: 10,
												}}
											/>
											<p
												style={{
													backgroundColor: 'transparent',
													border: 'none',
													color: '#18181B',
													alignSelf: 'center',
													margin: 0,
													fontSize: fontText().fontSize,
													lineHeight: fontText().lineHeight,
													fontWeight: 500,
													cursor: 'pointer',
												}}
												id="contato"
												onClick={() => scrollToSection('form-section')}
											>
												CONTATO
											</p>
											<div
												style={{
													borderBottom: '1px solid #D4D4D8',
													marginTop: 10,
													marginBottom: 10,
												}}
											/>
										</div>
									</div>
								) : (
									<img
										src={iconMenu}
										alt="icon menu"
										style={{ pointerEvents: 'none' }}
									/>
								)}
							</button>
						</div>
					)}
				</div>
			) : (
				<img
					src={logoPath}
					height={32}
					width={160}
					alt="logoPath"
					draggable={false}
					style={{
						pointerEvents: 'none',
						margin: 0,
					}}
				/>
			)}
			{deviceType !== 'mobile' && (
				<div
					style={{
						justifyContent: 'space-between',
						flexDirection: 'row',
						display: 'flex',
						minWidth: '560px',
						alignItems: 'center',
						gap: deviceType === 'tablet' ? '16px' : '32px',
					}}
				>
					<button
						style={{
							backgroundColor: 'transparent',
							border: 'none',
							color: '#FAFAFA',
							alignSelf: 'center',
							margin: 0,
							fontSize: fontText().fontSize,
							lineHeight: fontText().lineHeight,
							paddingLeft: deviceType === 'desktop' ? '0' : '8%',
							cursor: 'pointer',
						}}
					>
						<p id="inicio" onClick={() => scrollToSection('header-section')}>
							{renderSvg(
								activeComponent === 'Header' ? 'Header' : 'ImageContainer'
							)}
						</p>
					</button>
					<button
						style={{
							backgroundColor: 'transparent',
							border: 'none',
							color: '#FAFAFA',
							alignSelf: 'center',
							margin: 0,
							fontSize: fontText().fontSize,
							lineHeight: fontText().lineHeight,
							cursor: 'pointer',
						}}
					>
						<p
							id="sobre-nos"
							onClick={() => scrollToSection('container-cards-section')}
						>
							{renderSvg(
								activeComponent === 'ContainerCards'
									? 'ContainerCards'
									: 'ComponentCards'
							)}
						</p>
					</button>
					<button
						style={{
							backgroundColor: 'transparent',
							border: 'none',
							color: '#FAFAFA',
							alignSelf: 'center',
							margin: 0,
							fontSize: fontText().fontSize,
							lineHeight: fontText().lineHeight,
							cursor: 'pointer',
						}}
					>
						<p
							id="solucoes"
							onClick={() => scrollToSection('background-solutions-section')}
						>
							{renderSvg(
								activeComponent === 'BackgroundSolutions'
									? 'BackgroundSolutions'
									: 'ContainerCarouselSolutions'
							)}
						</p>
					</button>
					<button
						style={{
							backgroundColor: 'transparent',
							border: 'none',
							color: '#FAFAFA',
							alignSelf: 'center',
							margin: 0,
							fontSize: fontText().fontSize,
							lineHeight: fontText().lineHeight,
							cursor: 'pointer',
						}}
					>
						<p
							id="metodologia"
							onClick={() => scrollToSection('component-product-section')}
						>
							{renderSvg('ComponentProduct')}
						</p>
					</button>
					<button
						style={{
							backgroundColor: 'transparent',
							border: 'none',
							color: '#FAFAFA',
							alignSelf: 'center',
							margin: 0,
							fontSize: fontText().fontSize,
							lineHeight: fontText().lineHeight,
							cursor: 'pointer',
						}}
					>
						<p id="contato" onClick={() => scrollToSection('form-section')}>
							{renderSvg(activeComponent === 'Form' ? 'Form' : 'Footer')}
						</p>
					</button>
				</div>
			)}
		</div>
	);
};

export default Header;
