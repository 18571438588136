import bgTeam from '../../assets/images/bg-team.jpg';
import iconDetailLeft from '../../assets/images/icon-detail-left.svg';
import iconDetailRight from '../../assets/images/icon-detail-right.svg';

export interface IBackgroundSolutionsDesktop {
	deviceType: string;
}

export default function BackgroundSolutionsDesktop({
	deviceType,
}: IBackgroundSolutionsDesktop) {
	const width = window.innerWidth;

	const widthCard = () => {
		if (width <= 820) {
			return { left: '3%', right: '3%' };
		} else if (width > 820 && width <= 1280) {
			return { left: '9%', right: '9%' };
		} else {
			return { left: '22%', right: '22%' };
		}
	};

	const verticalAlignMobile = {
		verticalAlign: 'bottom',
		marginTop: '20px',
	};

	const verticalAlignDesktop = {
		verticalAlign: 'bottom',
		marginTop: '20px',
	};

	return (
		<div
			id="background-solutions-section"
			style={{
				backgroundImage: `url(${bgTeam})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				minHeight: deviceType === 'mobile' ? '70vh' : '80vh',
				maxHeight: deviceType === 'mobile' ? '90vh' : '90vh',
				position: 'relative',
				justifyContent: 'space-between',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					textAlign: 'center',
					justifyItems: 'center',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: '8px',
					backgroundColor: ' rgba(0, 0, 0, 0.70)',
					position: 'absolute',
					height: deviceType === 'tablet' ? '9vh' : '16vh',
					top: deviceType === 'tablet' ? '38%' : '33%',
					left: widthCard().left,
					right: widthCard().right,
					padding: deviceType === 'mobile' ? 16 : 48,
					gap: 16,
					margin: 0,
				}}
			>
				<p
					style={{
						color: '#FAFAFA',
						fontSize: deviceType === 'mobile' ? 16 : 34,
						fontWeight: '800',
						width: '100%',
						boxSizing: 'border-box',
						margin: 0,
						maxHeight: '85%',
					}}
				>
					<img
						src={iconDetailLeft}
						height={deviceType === 'mobile' ? 10 : 16}
						style={{
							verticalAlign: 'top',
							marginTop: '2px',
							alignItems: 'left',
						}}
						alt="icon detail left"
					/>
					COMO PODEMOS SER ÚTEIS?
					<img
						src={iconDetailRight}
						style={
							deviceType === 'mobile'
								? verticalAlignMobile
								: verticalAlignDesktop
						}
						height={deviceType === 'mobile' ? 10 : 16}
						alt="icon detail left"
					/>
				</p>
				<p
					style={{
						color: '#D4D4D8',
						fontSize: deviceType === 'mobile' ? 14 : 20,
						fontWeight: 500,
						lineHeight: '22px',
						width: '100%',
						margin: 0,
					}}
				>
					Conheça as soluções disponíveis para impulsionar seu negócio
				</p>
			</div>
		</div>
	);
}
