import useGalaxyFold from '../../hooks/useGalaxyFold';
import bgTeam from '../../assets/images/bg-team.jpg';

export default function BackgroundSolutionsMobile() {
	const isGalaxyFold = useGalaxyFold();

	return (
		<div
			id="background-solutions-section"
			style={{
				height: '100%',
				minHeight: '70vh',
				maxHeight: '90vh',
				position: 'relative',
			}}
		>
			<img
				src={bgTeam}
				alt="bg team"
				style={{
					objectFit: 'cover',
					objectPosition: 'center',
					width: '100%',
					height: '100%',
					position: 'absolute',
					top: '0%',
					left: '0%',
					right: '0%',
					bottom: '0%',
					pointerEvents: 'none',
					boxSizing: 'border-box',
				}}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					height: isGalaxyFold ? '53%' : '28%',
					maxHeight: '100%',
					border: 0,
					right: 0,
					width: '91.5%',
					backgroundColor: 'rgba(0, 0, 0, 0.70)',
					borderRadius: '8px',
					textAlign: 'center',
					justifyItems: 'center',
					position: 'absolute',
					top: isGalaxyFold ? '27%' : '35%',
					left: '4%',
					boxSizing: 'border-box',
				}}
			>
				<p
					style={{
						color: '#FAFAFA',
						fontSize: '16px',
						fontWeight: '800',
						width: isGalaxyFold ? '70%' : '100%',
						lineHeight: '33px',
						height: '40%',
						margin: 0,
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="13"
						viewBox="0 0 12 13"
						style={{ verticalAlign: 'top', marginTop: '1px' }}
						fill="none"
					>
						<path d="M12 0.5H0V12.5H3.42857V3.92857H12V0.5Z" fill="#2A6173" />
					</svg>
					COMO PODEMOS SER ÚTEIS?
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="13"
						viewBox="0 0 12 13"
						style={{
							verticalAlign: 'bottom',
							marginBottom: '0px',
						}}
						fill="none"
					>
						<path d="M0 12.5H12V0.5H8.57143V9.07143H0V12.5Z" fill="#2A6173" />
					</svg>
				</p>
				<p
					style={{
						color: '#D4D4D8',
						fontSize: 14,
						fontWeight: 500,
						lineHeight: '22px',
						width: '294px',
						margin: 0,
					}}
				>
					Conheça as soluções disponíveis para impulsionar seu negócio
				</p>
			</div>
		</div>
	);
}
