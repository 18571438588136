import React from 'react';

interface SpinnerProps {
	size?: number;
	color?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 40, color = '#000000' }) => {
	const spinnerStyle: React.CSSProperties = {
		width: size,
		height: size,
		border: '4px solid rgba(0, 0, 0, 0.1)',
		borderTop: `4px solid ${color}`,
		borderRadius: '50%',
		animation: 'spin 1s linear infinite',
	};

	return <div style={spinnerStyle}></div>;
};

export default Spinner;

const styles = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const styleElement = document.createElement('style');
styleElement.appendChild(document.createTextNode(styles));
document.head.appendChild(styleElement);
