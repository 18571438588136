interface IContainerCards {
	deviceType: string;
}

export default function ContainerCards({ deviceType }: IContainerCards) {
	const padding = () => {
		if (deviceType === 'mobile') {
			return { paddingTop: '48px', paddingBottom: '48px' };
		} else if (deviceType === 'tablet') {
			return { paddingTop: '64px', paddingBottom: '64px' };
		} else {
			return { paddingTop: '128px', paddingBottom: '128px' };
		}
	};

	const width = window.innerWidth;

	const isSmallMobile: boolean = width <= 344 && deviceType === 'mobile';

	const detailBottom = () => ({
		verticalAlign: 'middle',
		marginTop: isSmallMobile ? '55px' : '22px',
	});

	const linearGradient = {
		background:
			deviceType === 'mobile'
				? 'linear-gradient(180deg, #6A9DA8 0%, #380F43 100%)'
				: 'linear-gradient(to left, #380F43, #8BBDC4, #380F43)',
	};

	const iconPartnership = () => {
		if (deviceType === 'tablet') {
			return (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
						fill="#2A6173"
					/>
					<path
						d="M23.9998 20.8889L18.5553 15.4444L23.9998 10L29.4442 15.4444L23.9998 20.8889ZM6.88867 38V31.7778C6.88867 30.8963 7.1933 30.1574 7.80256 29.5611C8.41182 28.9648 9.14423 28.6667 9.99978 28.6667H15.0942C15.6127 28.6667 16.1053 28.7963 16.572 29.0556C17.0387 29.3148 17.4146 29.6648 17.6998 30.1056C18.4516 31.1167 19.3785 31.9074 20.4803 32.4778C21.5822 33.0481 22.7553 33.3333 23.9998 33.3333C25.2702 33.3333 26.4563 33.0481 27.5581 32.4778C28.66 31.9074 29.5739 31.1167 30.2998 30.1056C30.6368 29.6648 31.0322 29.3148 31.4859 29.0556C31.9396 28.7963 32.4128 28.6667 32.9053 28.6667H37.9998C38.8813 28.6667 39.6202 28.9648 40.2165 29.5611C40.8128 30.1574 41.1109 30.8963 41.1109 31.7778V38H30.222V34.4611C29.3146 35.1093 28.3359 35.6019 27.2859 35.9389C26.2359 36.2759 25.1405 36.4444 23.9998 36.4444C22.885 36.4444 21.7961 36.2694 20.7331 35.9194C19.6702 35.5694 18.685 35.0704 17.7776 34.4222V38H6.88867ZM11.5553 27.1111C10.259 27.1111 9.15719 26.6574 8.24978 25.75C7.34238 24.8426 6.88867 23.7407 6.88867 22.4444C6.88867 21.1222 7.34238 20.0139 8.24978 19.1194C9.15719 18.225 10.259 17.7778 11.5553 17.7778C12.8776 17.7778 13.9859 18.225 14.8803 19.1194C15.7748 20.0139 16.222 21.1222 16.222 22.4444C16.222 23.7407 15.7748 24.8426 14.8803 25.75C13.9859 26.6574 12.8776 27.1111 11.5553 27.1111ZM36.4442 27.1111C35.1479 27.1111 34.0461 26.6574 33.1387 25.75C32.2313 24.8426 31.7776 23.7407 31.7776 22.4444C31.7776 21.1222 32.2313 20.0139 33.1387 19.1194C34.0461 18.225 35.1479 17.7778 36.4442 17.7778C37.7665 17.7778 38.8748 18.225 39.7692 19.1194C40.6637 20.0139 41.1109 21.1222 41.1109 22.4444C41.1109 23.7407 40.6637 24.8426 39.7692 25.75C38.8748 26.6574 37.7665 27.1111 36.4442 27.1111Z"
						fill="#FAFAFA"
					/>
				</svg>
			);
		} else {
			return (
				<svg
					width="64"
					height="64"
					viewBox="0 0 64 64"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z"
						fill="#2A6173"
					/>
					<path
						d="M32.0001 27.5556L24.2223 19.7778L32.0001 12L39.7779 19.7778L32.0001 27.5556ZM7.55566 52V43.1111C7.55566 41.8519 7.99085 40.7963 8.86122 39.9444C9.73159 39.0926 10.7779 38.6667 12.0001 38.6667H19.2779C20.0186 38.6667 20.7223 38.8519 21.389 39.2222C22.0557 39.5926 22.5927 40.0926 23.0001 40.7222C24.0742 42.1667 25.3983 43.2963 26.9723 44.1111C28.5464 44.9259 30.2223 45.3333 32.0001 45.3333C33.8149 45.3333 35.5094 44.9259 37.0834 44.1111C38.6575 43.2963 39.9631 42.1667 41.0001 40.7222C41.4816 40.0926 42.0464 39.5926 42.6946 39.2222C43.3427 38.8519 44.0186 38.6667 44.7223 38.6667H52.0001C53.2594 38.6667 54.3149 39.0926 55.1668 39.9444C56.0186 40.7963 56.4446 41.8519 56.4446 43.1111V52H40.889V46.9444C39.5927 47.8704 38.1946 48.5741 36.6946 49.0556C35.1946 49.537 33.6297 49.7778 32.0001 49.7778C30.4075 49.7778 28.852 49.5278 27.3334 49.0278C25.8149 48.5278 24.4075 47.8148 23.1112 46.8889V52H7.55566ZM14.2223 36.4444C12.3705 36.4444 10.7964 35.7963 9.50011 34.5C8.20381 33.2037 7.55566 31.6296 7.55566 29.7778C7.55566 27.8889 8.20381 26.3056 9.50011 25.0278C10.7964 23.75 12.3705 23.1111 14.2223 23.1111C16.1112 23.1111 17.6946 23.75 18.9723 25.0278C20.2501 26.3056 20.889 27.8889 20.889 29.7778C20.889 31.6296 20.2501 33.2037 18.9723 34.5C17.6946 35.7963 16.1112 36.4444 14.2223 36.4444ZM49.7779 36.4444C47.926 36.4444 46.352 35.7963 45.0557 34.5C43.7594 33.2037 43.1112 31.6296 43.1112 29.7778C43.1112 27.8889 43.7594 26.3056 45.0557 25.0278C46.352 23.75 47.926 23.1111 49.7779 23.1111C51.6668 23.1111 53.2501 23.75 54.5279 25.0278C55.8057 26.3056 56.4446 27.8889 56.4446 29.7778C56.4446 31.6296 55.8057 33.2037 54.5279 34.5C53.2501 35.7963 51.6668 36.4444 49.7779 36.4444Z"
						fill="#FAFAFA"
					/>
				</svg>
			);
		}
	};

	const iconExperience = () => {
		if (deviceType === 'tablet') {
			return (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
						fill="#2A6173"
					/>
					<path
						d="M13.1111 38C12.2556 38 11.5231 37.6954 10.9139 37.0861C10.3046 36.4769 10 35.7444 10 34.8889V10H13.1111V34.8889H38V38H13.1111ZM14.6667 33.3333V19.3333H20.8889V33.3333H14.6667ZM22.4444 33.3333V25.5556H28.6667V33.3333H22.4444ZM30.2222 33.3333V13.6556H36.4444V33.3333H30.2222Z"
						fill="#FAFAFA"
					/>
				</svg>
			);
		} else {
			return (
				<svg
					width="64"
					height="64"
					viewBox="0 0 64 64"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z"
						fill="#2A6173"
					/>
					<path
						d="M16.4444 52C15.2222 52 14.1759 51.5648 13.3056 50.6944C12.4352 49.8241 12 48.7778 12 47.5556V12H16.4444V47.5556H52V52H16.4444ZM18.6667 45.3333V25.3333H27.5556V45.3333H18.6667ZM29.7778 45.3333V34.2222H38.6667V45.3333H29.7778ZM40.8889 45.3333V17.2222H49.7778V45.3333H40.8889Z"
						fill="#FAFAFA"
					/>
				</svg>
			);
		}
	};

	const iconSpecialists = () => {
		if (deviceType === 'tablet') {
			return (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
						fill="#2A6173"
					/>
					<path
						d="M37.9998 31.7778V21.0444L23.9998 28.6667L6.88867 19.3333L23.9998 10L41.1109 19.3333V31.7778H37.9998ZM23.9998 38L13.1109 32.0889V24.3111L23.9998 30.2222L34.8887 24.3111V32.0889L23.9998 38Z"
						fill="#FAFAFA"
					/>
				</svg>
			);
		} else {
			return (
				<svg
					width="64"
					height="64"
					viewBox="0 0 64 64"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z"
						fill="#2A6173"
					/>
					<path
						d="M52.0001 43.1111V27.7778L32.0001 38.6667L7.55566 25.3333L32.0001 12L56.4446 25.3333V43.1111H52.0001ZM32.0001 52L16.4446 43.5556V32.4444L32.0001 40.8889L47.5557 32.4444V43.5556L32.0001 52Z"
						fill="#FAFAFA"
					/>
				</svg>
			);
		}
	};

	return (
		<div
			id="container-cards-section"
			style={{
				paddingLeft: deviceType !== 'mobile' ? '10%' : '5%',
				paddingRight: deviceType !== 'mobile' ? '10%' : '5%',
				...padding(),
				gap: 64,
				alignItems: 'center',
				...linearGradient,
			}}
		>
			{deviceType === 'mobile' ? (
				<p
					style={{
						color: '#FAFAFA',
						display: 'flex',
						justifyContent: 'center',
						fontSize: 24,
						fontWeight: 800,
						paddingBottom: 9,
						textAlign: 'center',
						marginTop: 0,
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
						style={{ verticalAlign: 'top', marginTop: '-1px' }}
					>
						<path d="M12 0H0V12H3.42857V3.42857H12V0Z" fill="#521262" />
					</svg>
					POR QUE A PATHBIT?
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
						style={detailBottom()}
					>
						<path d="M0 12H12V0H8.57143V8.57143H0V12Z" fill="#521262" />
					</svg>
				</p>
			) : (
				<p
					style={{
						color: '#FAFAFA',
						display: 'flex',
						justifyContent: 'center',
						fontSize: deviceType === 'tablet' ? 36 : 42,
						fontWeight: 800,
						marginTop: 0,
						paddingTop: 0,
						paddingBottom: deviceType === 'tablet' ? 12 : 18,
					}}
				>
					{deviceType === 'tablet' ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="17"
							height="16"
							viewBox="0 0 17 16"
							fill="none"
							style={{ verticalAlign: 'top', marginTop: 2 }}
						>
							<path d="M16.5 0H0.5V16H5.07143V4.57143H16.5V0Z" fill="#521262" />
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							style={{ verticalAlign: 'top', marginTop: 3 }}
						>
							<path d="M18 0H0V18H5.14286V5.14286H18V0Z" fill="#521262" />
						</svg>
					)}
					POR QUE A PATHBIT?
					{deviceType === 'tablet' ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							style={{ verticalAlign: 'bottom', marginTop: 34 }}
							fill="none"
						>
							<path d="M0.5 16H16.5V0H11.9286V11.4286H0.5V16Z" fill="#521262" />
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							style={{ verticalAlign: 'bottom', marginTop: 40 }}
							fill="none"
						>
							<path d="M0 18H18V0H12.8571V12.8571H0V18Z" fill="#521262" />
						</svg>
					)}
				</p>
			)}
			{deviceType === 'mobile' ? (
				<div
					style={{
						gap: 32,
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
					}}
				>
					<div
						style={{
							backgroundColor: '#FAFAFA',
							borderRadius: 8,
							paddingLeft: 16,
							paddingRight: 16,
							paddingTop: 16,
							paddingBottom: 16,
							alignItems: 'flex-start',
						}}
					>
						<div>
							<svg
								width="36"
								height="36"
								viewBox="0 0 36 36"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="36" height="36" rx="4" fill="#2A6173" />
								<path
									d="M17.9996 15.7778L14.1107 11.8889L17.9996 8L21.8885 11.8889L17.9996 15.7778ZM5.77734 28V23.5556C5.77734 22.9259 5.99494 22.3981 6.43012 21.9722C6.86531 21.5463 7.38846 21.3333 7.99957 21.3333H11.6385C12.0088 21.3333 12.3607 21.4259 12.694 21.6111C13.0273 21.7963 13.2959 22.0463 13.4996 22.3611C14.0366 23.0833 14.6986 23.6481 15.4857 24.0556C16.2727 24.463 17.1107 24.6667 17.9996 24.6667C18.907 24.6667 19.7542 24.463 20.5412 24.0556C21.3283 23.6481 21.981 23.0833 22.4996 22.3611C22.7403 22.0463 23.0227 21.7963 23.3468 21.6111C23.6709 21.4259 24.0088 21.3333 24.3607 21.3333H27.9996C28.6292 21.3333 29.157 21.5463 29.5829 21.9722C30.0088 22.3981 30.2218 22.9259 30.2218 23.5556V28H22.444V25.4722C21.7959 25.9352 21.0968 26.287 20.3468 26.5278C19.5968 26.7685 18.8144 26.8889 17.9996 26.8889C17.2033 26.8889 16.4255 26.7639 15.6662 26.5139C14.907 26.2639 14.2033 25.9074 13.5551 25.4444V28H5.77734ZM9.11068 20.2222C8.18475 20.2222 7.39771 19.8981 6.74957 19.25C6.10142 18.6019 5.77734 17.8148 5.77734 16.8889C5.77734 15.9444 6.10142 15.1528 6.74957 14.5139C7.39771 13.875 8.18475 13.5556 9.11068 13.5556C10.0551 13.5556 10.8468 13.875 11.4857 14.5139C12.1246 15.1528 12.444 15.9444 12.444 16.8889C12.444 17.8148 12.1246 18.6019 11.4857 19.25C10.8468 19.8981 10.0551 20.2222 9.11068 20.2222ZM26.8885 20.2222C25.9625 20.2222 25.1755 19.8981 24.5273 19.25C23.8792 18.6019 23.5551 17.8148 23.5551 16.8889C23.5551 15.9444 23.8792 15.1528 24.5273 14.5139C25.1755 13.875 25.9625 13.5556 26.8885 13.5556C27.8329 13.5556 28.6246 13.875 29.2635 14.5139C29.9023 15.1528 30.2218 15.9444 30.2218 16.8889C30.2218 17.8148 29.9023 18.6019 29.2635 19.25C28.6246 19.8981 27.8329 20.2222 26.8885 20.2222Z"
									fill="#FAFAFA"
								/>
							</svg>
							<p
								style={{
									fontSize: 18,
									fontWeight: 700,
									color: '#18181B',
								}}
							>
								PARCERIA ÚNICA
							</p>
							<p
								style={{
									fontSize: 13,
									color: '#71717A',
									fontWeight: 400,
									lineHeight: '19px',
									margin: 0,
								}}
							>
								Mais do que uma consultoria, somos seu parceiro estratégico.
								Acreditamos sinceramente, que nosso sucesso está diretamente
								relacionado ao seu progresso e crescimento.
							</p>
						</div>
					</div>
					<div
						style={{
							backgroundColor: '#FAFAFA',
							borderRadius: 8,
							paddingLeft: 16,
							paddingRight: 16,
							paddingTop: 16,
							paddingBottom: 16,
							alignItems: 'flex-start',
						}}
					>
						<div>
							<svg
								width="36"
								height="36"
								viewBox="0 0 36 36"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="36" height="36" rx="4" fill="#2A6173" />
								<path
									d="M10.2222 28C9.61111 28 9.08796 27.7824 8.65278 27.3472C8.21759 26.912 8 26.3889 8 25.7778V8H10.2222V25.7778H28V28H10.2222ZM11.3333 24.6667V14.6667H15.7778V24.6667H11.3333ZM16.8889 24.6667V19.1111H21.3333V24.6667H16.8889ZM22.4444 24.6667V10.6111H26.8889V24.6667H22.4444Z"
									fill="#FAFAFA"
								/>
							</svg>

							<p
								style={{
									fontSize: 18,
									fontWeight: 700,
									color: '#18181B',
								}}
							>
								DÉCADAS DE EXPERIÊNCIA
							</p>
							<p
								style={{
									fontSize: 13,
									color: '#71717A',
									fontWeight: 400,
									lineHeight: '19px',
									margin: 0,
								}}
							>
								Nossa liderança, com mais de 20 anos de experiência nos mercados
								Financeiro e Tecnológico, compreende profundamente suas
								necessidades para oferecer soluções personalizadas.
							</p>
						</div>
					</div>
					<div
						style={{
							backgroundColor: '#FAFAFA',
							borderRadius: 8,
							paddingLeft: 16,
							paddingRight: 16,
							paddingTop: 16,
							paddingBottom: 16,
							alignItems: 'flex-start',
						}}
					>
						<div>
							<svg
								width="36"
								height="36"
								viewBox="0 0 36 36"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="36" height="36" rx="4" fill="#2A6173" />
								<path
									d="M27.9996 23.5556V15.8889L17.9996 21.3333L5.77734 14.6667L17.9996 8L30.2218 14.6667V23.5556H27.9996ZM17.9996 28L10.2218 23.7778V18.2222L17.9996 22.4444L25.7773 18.2222V23.7778L17.9996 28Z"
									fill="#FAFAFA"
								/>
							</svg>

							<p style={{ fontSize: 18, fontWeight: 700, color: '#18181B' }}>
								TIME DE ESPECIALISTAS
							</p>
							<p
								style={{
									fontSize: 13,
									color: '#71717A',
									fontWeight: 400,
									lineHeight: '19px',
									margin: 0,
								}}
							>
								Abraçamos desafios com confiança. Usamos soluções avançadas e
								nossa paixão para superar as suas expectativas, transformando
								com sucesso seus projetos em realidade.
							</p>
						</div>
					</div>
				</div>
			) : (
				<div
					style={{
						gap: deviceType === 'tablet' ? 24 : 32,
						display: 'flex',
						justifyContent: 'center',
						flexDirection: deviceType === 'tablet' ? 'column' : 'row',
					}}
				>
					<div
						style={{
							width: deviceType === 'tablet' ? 'auto' : 379,
							height: deviceType === 'tablet' ? 'auto' : 'auto',
							backgroundColor: '#FAFAFA',
							borderRadius: 16,
							paddingLeft: deviceType === 'tablet' ? 24 : 32,
							paddingRight: deviceType === 'tablet' ? 24 : 32,
							paddingTop: deviceType === 'tablet' ? 24 : 48,
							paddingBottom: deviceType === 'tablet' ? 24 : 48,
							alignItems: 'flex-start',
						}}
					>
						<div>
							{iconPartnership()}

							<p
								style={{
									fontSize: deviceType === 'desktop' ? 19 : 15,
									fontWeight: 700,
									color: '#18181B',
								}}
							>
								PARCERIA ÚNICA
							</p>
							<p
								style={{
									fontSize: deviceType === 'tablet' ? 14 : 17,
									color: '#71717A',
									lineHeight: deviceType === 'tablet' ? '22px' : '24px',
									fontWeight: 400,
									margin: 0,
								}}
							>
								Mais do que uma consultoria, somos seu parceiro estratégico.
								Acreditamos sinceramente, que nosso sucesso está diretamente
								relacionado ao seu progresso e crescimento.
							</p>
						</div>
					</div>
					<div
						style={{
							width: deviceType === 'tablet' ? 'auto' : 379,
							height: deviceType === 'tablet' ? 'auto' : 'auto',
							backgroundColor: '#FAFAFA',
							borderRadius: 16,
							paddingLeft: deviceType === 'tablet' ? 24 : 32,
							paddingRight: deviceType === 'tablet' ? 24 : 32,
							paddingTop: deviceType === 'tablet' ? 24 : 48,
							paddingBottom: deviceType === 'tablet' ? 24 : 48,
							alignItems: 'flex-start',
						}}
					>
						<div>
							{iconExperience()}

							<p
								style={{
									fontSize: deviceType === 'desktop' ? 19 : 15,
									fontWeight: 700,
									color: '#18181B',
								}}
							>
								DÉCADAS DE EXPERIÊNCIA
							</p>
							<p
								style={{
									fontSize: deviceType === 'tablet' ? 14 : 17,
									color: '#71717A',
									lineHeight: deviceType === 'tablet' ? '22px' : '24px',
									fontWeight: 400,
									margin: 0,
								}}
							>
								Nossa liderança, com mais de 20 anos de experiência nos mercados
								Financeiro e Tecnológico, compreende profundamente suas
								necessidades para oferecer soluções personalizadas.
							</p>
						</div>
					</div>
					<div
						style={{
							width: deviceType === 'tablet' ? 'auto' : 379,
							height: deviceType === 'tablet' ? 'auto' : 'auto',
							gap: 16,
							backgroundColor: '#FAFAFA',
							borderRadius: 16,
							paddingLeft: deviceType === 'tablet' ? 24 : 32,
							paddingRight: deviceType === 'tablet' ? 24 : 32,
							paddingTop: deviceType === 'tablet' ? 24 : 48,
							paddingBottom: deviceType === 'tablet' ? 24 : 48,
							alignItems: 'flex-start',
						}}
					>
						<div>
							{iconSpecialists()}
							<p
								style={{
									fontSize: deviceType === 'desktop' ? 19 : 15,
									fontWeight: 700,
									color: '#18181B',
								}}
							>
								TIME DE ESPECIALISTAS
							</p>
							<p
								style={{
									fontSize: deviceType === 'tablet' ? 14 : 17,
									color: '#71717A',
									lineHeight: deviceType === 'tablet' ? '22px' : '24px',
									fontWeight: 400,
									margin: 0,
								}}
							>
								Abraçamos desafios com confiança. Usamos soluções avançadas e
								nossa paixão para superar as suas expectativas, transformando
								com sucesso seus projetos em realidade.
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
