import bgPartnership from '../../assets/images/bg-partnership.jpg';

interface IImageContainerDesktop {
	deviceType: string;
}

export default function ImageContainerDesktop({
	deviceType,
}: IImageContainerDesktop) {
	const marginGrid = '10%';

	const fontTitle = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '36px', lineHeight: '50px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '56px', lineHeight: '78px' };
		} else {
			return { fontSize: '80px', lineHeight: '112px' };
		}
	};

	const fontSubtitle = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '16px', lineHeight: '22px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '24px', lineHeight: '33px' };
		} else {
			return { fontSize: '32px', lineHeight: '44px' };
		}
	};

	const width = window.innerWidth;

	const height = window.innerHeight;

	const isTabletPro: boolean = width === 1024 && height === 1366;

	const designText = () => {
		if (width <= 960) {
			return { top: '47%', right: '0%' };
		} else if (isTabletPro === true) {
			return { top: '50%', right: '0%' };
		} else if (width <= 1700) {
			return { top: '48%', right: '0%' };
		} else {
			return { top: '46%', right: '0%' };
		}
	};

	return (
		<div
			id="image-container-section"
			style={{
				position: 'relative',
				width: '100%',
				overflow: 'hidden',
			}}
		>
			<img
				src={bgPartnership}
				alt="bglogo"
				style={{
					width: '100%',
					height: width < 960 || isTabletPro === true ? '100%' : '100vh',
					objectFit: 'cover',
					objectPosition: 'center',
					display: 'block',
					margin: 'auto',
					pointerEvents: 'none',
					boxSizing: 'border-box',
				}}
				draggable={false}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
					justifyContent: 'end',
					height: '25%',
					width: '100%',
					maxHeight: 200,
					zIndex: 0,
					position: 'absolute',
					top: designText().top,
					right: designText().right,
					fontWeight: '500',
					margin: marginGrid,
					justifyItems: 'end',
					textAlign: 'right',
				}}
			>
				<p
					style={{
						fontSize: fontSubtitle().fontSize,
						lineHeight: fontSubtitle().lineHeight,
						margin: '0px',
						color: '#D4D4D8',
						fontWeight: 500,
					}}
				>
					Transformamos
				</p>
				<p
					style={{
						fontSize: fontTitle().fontSize,
						lineHeight: fontTitle().lineHeight,
						fontWeight: 900,
						margin: '0px',
						color: '#FAFAFA',
					}}
				>
					PARCERIAS
				</p>
				<p
					style={{
						fontSize: fontSubtitle().fontSize,
						lineHeight: fontSubtitle().lineHeight,
						margin: '0px',
						color: '#D4D4D8',
						fontWeight: 500,
					}}
				>
					em progresso sustentável
				</p>
			</div>
		</div>
	);
}
