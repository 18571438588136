import { useEffect, useState } from 'react';

function useGalaxyFold() {
	const [isGalaxyFold, setGalaxyFold] = useState(false);

	useEffect(() => {
		const updateWidth = () => {
			const width = window.innerWidth;

			// Verifica se o dispositivo tem a largura de um Galaxy Fold
			if (width === 280) {
				setGalaxyFold(true);
			} else {
				setGalaxyFold(false);
			}
		};

		// Chame a função para definir o tamanho da janela inicial
		updateWidth();

		// Adicione um ouvinte de redimensionamento da janela para atualizar o tamanho da janela
		window.addEventListener('resize', updateWidth);

		// Lembre-se de remover o ouvinte de redimensionamento quando o componente for desmontado
		return () => {
			window.removeEventListener('resize', updateWidth);
		};
	}, []);

	return isGalaxyFold;
}

export default useGalaxyFold;
