export interface IComponentCards {
	deviceType: string;
}

export default function ContainerAboutDetails({ deviceType }: IComponentCards) {
	const isMobile = deviceType === 'mobile';

	const padding = () => {
		if (deviceType === 'mobile') {
			return { paddingTop: '48px', paddingBottom: '48px' };
		} else if (deviceType === 'tablet') {
			return { paddingTop: '64px', paddingBottom: '64px' };
		} else {
			return { paddingTop: '128px', paddingBottom: '128px' };
		}
	};

	const fontTitle = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '24px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '36px' };
		} else {
			return { fontSize: '42px' };
		}
	};

	const fontSubtitle = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '16px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '18px' };
		} else {
			return { fontSize: '20px' };
		}
	};

	const fontIcons = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '16px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '11.6px', width: '165px' };
		} else {
			return { fontSize: '19.8px', width: '110px' };
		}
	};

	const fontParagraph = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '14px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '12.6px', width: '86%' };
		} else {
			return { fontSize: '17px', width: '72%' };
		}
	};

	const detailTop = () => {
		if (deviceType === 'mobile') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 0 }}
				>
					<path d="M12 0H0V12H3.42857V3.42857H12V0Z" fill="#2A6173" />
				</svg>
			);
		} else if (deviceType === 'tablet') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					style={{ verticalAlign: 'bottom', marginBottom: 32 }}
				>
					<path d="M16 0H0V16H4.57143V4.57143H16V0Z" fill="#2A6173" />
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					style={{ verticalAlign: 'middle', marginBottom: 45 }}
				>
					<path d="M18 0H0V18H5.14286V5.14286H18V0Z" fill="#2A6173" />
				</svg>
			);
		}
	};

	const detailBottom = () => {
		if (deviceType === 'mobile') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 22 }}
				>
					<path d="M0 12H12V0H8.57143V8.57143H0V12Z" fill="#2A6173" />
				</svg>
			);
		} else if (deviceType === 'tablet') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 32 }}
				>
					<path d="M0 16H16V0H11.4286V11.4286H0V16Z" fill="#2A6173" />
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 39 }}
				>
					<path d="M0 18H18V0H12.8571V12.8571H0V18Z" fill="#2A6173" />
				</svg>
			);
		}
	};

	const arrowIcon = () => {
		if (deviceType !== 'desktop') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="20"
					viewBox="0 0 12 20"
					fill="none"
				>
					<path
						d="M7.61428 10L0.618762 3.00448C0.289913 2.67564 0.125488 2.2571 0.125488 1.74888C0.125488 1.24066 0.289913 0.822123 0.618762 0.493274C0.947611 0.164425 1.36615 0 1.87437 0C2.38259 0 2.80112 0.164425 3.12997 0.493274L11.3811 8.74439C11.5605 8.92377 11.6875 9.11809 11.7623 9.32735C11.837 9.53662 11.8744 9.76084 11.8744 10C11.8744 10.2392 11.837 10.4634 11.7623 10.6726C11.6875 10.8819 11.5605 11.0762 11.3811 11.2556L3.12997 19.5067C2.80112 19.8356 2.38259 20 1.87437 20C1.36615 20 0.947611 19.8356 0.618762 19.5067C0.289913 19.1779 0.125488 18.7593 0.125488 18.2511C0.125488 17.7429 0.289913 17.3244 0.618762 16.9955L7.61428 10Z"
						fill="#D4D4D8"
					/>
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="80"
					height="134"
					viewBox="0 0 80 134"
					fill="none"
				>
					<path
						d="M50.8161 67L3.94617 20.13C1.74288 17.9268 0.641235 15.1226 0.641235 11.7175C0.641235 8.31241 1.74288 5.50822 3.94617 3.30493C6.14946 1.10164 8.95364 0 12.3587 0C15.7638 0 18.568 1.10164 20.7713 3.30493L76.0538 58.5874C77.2556 59.7892 78.1069 61.0912 78.6076 62.4933C79.1084 63.8954 79.3587 65.3976 79.3587 67C79.3587 68.6024 79.1084 70.1046 78.6076 71.5067C78.1069 72.9088 77.2556 74.2108 76.0538 75.4126L20.7713 130.695C18.568 132.898 15.7638 134 12.3587 134C8.95364 134 6.14946 132.898 3.94617 130.695C1.74288 128.492 0.641235 125.688 0.641235 122.283C0.641235 118.877 1.74288 116.073 3.94617 113.87L50.8161 67Z"
						fill="#D4D4D8"
					/>
				</svg>
			);
		}
	};

	return (
		<div
			id="component-cards-section"
			style={{ maxWidth: '100%', margin: '0 auto' }}
		>
			<div
				style={{
					background: 'linear-gradient(to right, #fafafa, #E4E4E7)',
					paddingLeft: deviceType !== 'mobile' ? '10%' : '5%',
					paddingRight: deviceType !== 'mobile' ? '10%' : '5%',
					...padding(),
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						flexDirection: 'row',
						display: 'flex',
						alignSelf: 'stretch',
					}}
				>
					<div
						style={{
							margin: '0 auto',
						}}
					>
						<p
							style={{
								textAlign: 'left',
								color: '#521262',
								...fontTitle(),
								lineHeight: '140%',
								fontWeight: 800,
								fontStyle: 'normal',
								margin: 0,
								justifyContent: isMobile ? 'left' : 'left',
							}}
						>
							{detailTop()}
							VALORES
							{detailBottom()}
						</p>
						<p
							style={{
								margin: 0,
								marginTop: deviceType === 'desktop' ? 5 : 3,
								paddingTop: '4px',
								color: '#71717A',
								fontFamily: 'Roboto, sans-serif',
								...fontSubtitle(),
								fontStyle: 'normal',
								fontWeight: 500,
								lineHeight: '23px',
								textAlign: 'left',
							}}
						>
							Inspire-se com os nossos princípios e conquiste um futuro
							promissor com a nossa parceria.
						</p>
						{deviceType !== 'desktop' ? (
							<div
								style={{
									borderBottom: '1px solid #D4D4D8',
									marginTop: 24,
									marginBottom: 32,
								}}
							></div>
						) : (
							<div style={{ marginTop: 24, marginBottom: 50 }}></div>
						)}
						{/* DIV MÃE */}
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: isMobile ? 'column' : 'row',
								gap: deviceType === 'tablet' ? '10px' : '0px',
							}}
						>
							<div
								style={{
									alignItems: 'left',
									display: 'flex',
									marginRight: deviceType !== 'tablet' ? 35 : '-12px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'left',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'left',
											flexDirection: 'row',
											marginBottom: 8,
										}}
									>
										{arrowIcon()}
										<h1
											style={{
												color: deviceType !== 'desktop' ? '#18181B' : '#1A3D50',
												...fontIcons(),
												fontWeight: 700,
												margin: 0,
												marginTop:
													deviceType === 'desktop'
														? '10px'
														: deviceType === 'tablet'
														? '2px'
														: '0px',
												lineHeight: deviceType === 'desktop' ? 'auto' : '140%',
												paddingLeft: deviceType !== 'desktop' ? '12px' : '20px',
												textAlign: 'left',
											}}
										>
											COLABORAÇÃO E IMPACTO
										</h1>
									</div>
									<p
										style={{
											textAlign: 'left',
											margin: 0,
											marginTop:
												deviceType === 'desktop'
													? '-64px'
													: deviceType === 'tablet'
													? '-2px'
													: '-3px',
											color: '#71717A',
											...fontParagraph(),
											fontWeight: 400,
											lineHeight: '140%',
											paddingLeft: deviceType !== 'desktop' ? '24px' : '99px',
											letterSpacing: '0.5px',
										}}
									>
										Construção do sucesso com nossos clientes.
									</p>
								</div>
							</div>
							<div
								style={{
									alignItems: 'left',
									marginLeft: isMobile ? 0 : 16,
									marginRight: isMobile ? 0 : 16,
									marginTop: deviceType === 'mobile' ? 24 : 0,
									marginBottom: deviceType === 'mobile' ? 24 : 0,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'left',
										width: '90%',
										marginRight:
											deviceType === 'desktop'
												? '-28px'
												: deviceType === 'tablet'
												? '-6px'
												: 'auto',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'left',
											flexDirection: 'row',
											marginBottom: 8,
										}}
									>
										{arrowIcon()}
										<h1
											style={{
												color: deviceType !== 'desktop' ? '#18181B' : '#1A3D50',
												...fontIcons(),
												fontWeight: 700,
												margin: 0,
												marginTop:
													deviceType === 'desktop'
														? '10px'
														: deviceType === 'tablet'
														? '2px'
														: '0px',
												lineHeight: deviceType === 'desktop' ? 'auto' : '140%',
												paddingLeft: deviceType !== 'desktop' ? '12px' : '20px',
												textAlign: 'left',
												width:
													deviceType === 'desktop'
														? '156px'
														: deviceType === 'tablet'
														? '212px'
														: '100%',
											}}
										>
											CRIATIVIDADE E INSPIRAÇÃO
										</h1>
									</div>
									<p
										style={{
											textAlign: 'left',
											margin: 0,
											marginTop:
												deviceType === 'desktop'
													? '-64px'
													: deviceType === 'tablet'
													? '-2px'
													: '-3px',
											color: '#71717A',
											...fontParagraph(),
											fontWeight: 400,
											lineHeight: '140%',
											paddingLeft: deviceType !== 'desktop' ? '24px' : '99px',
											letterSpacing: '0.5px',
										}}
									>
										Consistência e inovação como motores de transformação.
									</p>
								</div>
							</div>
							<div
								style={{
									alignItems: 'left',
									display: 'flex',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'left',
										marginLeft:
											deviceType === 'desktop'
												? '-18px'
												: deviceType === 'tablet'
												? '-46px'
												: '',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'left',
											flexDirection: 'row',
											marginBottom: 8,
										}}
									>
										{arrowIcon()}
										<h1
											style={{
												color: deviceType !== 'desktop' ? '#18181B' : '#1A3D50',
												...fontIcons(),
												fontWeight: 700,
												margin: 0,
												marginTop:
													deviceType === 'desktop'
														? '10px'
														: deviceType === 'tablet'
														? '2px'
														: '0px',
												lineHeight: deviceType === 'desktop' ? 'auto' : '140%',
												paddingLeft: deviceType !== 'desktop' ? '12px' : '20px',
												textAlign: 'left',
												width:
													deviceType === 'desktop'
														? '62%'
														: deviceType === 'tablet'
														? '198px'
														: '100%',
											}}
										>
											CREDIBILIDADE E INTEGRIDADE
										</h1>
									</div>
									<p
										style={{
											textAlign: 'left',
											margin: 0,
											marginTop:
												deviceType === 'desktop'
													? '-64px'
													: deviceType === 'tablet'
													? '-2px'
													: '-3px',
											color: '#71717A',
											...fontParagraph(),
											fontWeight: 400,
											lineHeight: '140%',
											paddingLeft: deviceType !== 'desktop' ? '24px' : '99px',
											letterSpacing: '0.5px',
										}}
									>
										Confiança na execução de cada serviço.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
