import { useEffect, useState } from 'react';

function useSetDevice() {
	const [deviceType, setDeviceType] = useState<'mobile' | 'tablet' | 'desktop'>(
		'desktop'
	);

	useEffect(() => {
		const updateDeviceType = () => {
			const width = window.innerWidth;
			if (width < 480) {
				setDeviceType('mobile');
			} else if (width < 768) {
				setDeviceType('mobile');
			} else if (width < 992) {
				setDeviceType('tablet');
			} else {
				setDeviceType('desktop');
			}
		};

		// Adicione um ouvinte de redimensionamento da janela para atualizar o tamanho da janela
		window.addEventListener('resize', updateDeviceType);

		// Chame a função para definir o tamanho da janela inicial
		updateDeviceType();

		// Lembre-se de remover o ouvinte de redimensionamento quando o componente for desmontado
		return () => {
			window.removeEventListener('resize', updateDeviceType);
		};
	}, [deviceType]);

	return deviceType;
}

export default useSetDevice;
