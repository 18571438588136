import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bgTeam from '../../assets/images/bg-path.jpg';
import UpAsp from '../../assets/images/up-asp.svg';
import BottomAsp from '../../assets/images/bottom-asp.svg';

export interface IComponentProduct {
	deviceType: string;
}

const Carousel: React.FC<IComponentProduct> = ({ deviceType }) => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 2400,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3600,
		arrows: false,
	};

	const padding = () => {
		if (deviceType === 'mobile') {
			return { paddingTop: '48px', paddingBottom: '48px' };
		} else if (deviceType === 'tablet') {
			return { paddingTop: '64px', paddingBottom: '64px' };
		} else {
			return { paddingTop: '128px', paddingBottom: '128px' };
		}
	};

	const fontTitle = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '24px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '36px' };
		} else {
			return { fontSize: '42px' };
		}
	};

	const fontSubtitle = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '16px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '18px' };
		} else {
			return { fontSize: '20px' };
		}
	};

	const fontParagraph = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '18px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '24px' };
		} else {
			return { fontSize: '28px' };
		}
	};

	const detailTop = () => {
		if (deviceType === 'mobile') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: '-1' }}
				>
					<path d="M12 0H0V12H3.42857V3.42857H12V0Z" fill="#2A6173" />
				</svg>
			);
		} else if (deviceType === 'tablet') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					style={{ verticalAlign: 'bottom', marginBottom: 35 }}
				>
					<path d="M16 0H0V16H4.57143V4.57143H16V0Z" fill="#2A6173" />
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					style={{ verticalAlign: 'middle', marginBottom: 47 }}
				>
					<path d="M18 0H0V18H5.14286V5.14286H18V0Z" fill="#2A6173" />
				</svg>
			);
		}
	};

	const detailBottom = () => {
		if (deviceType === 'mobile') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 23 }}
				>
					<path d="M0 12H12V0H8.57143V8.57143H0V12Z" fill="#2A6173" />
				</svg>
			);
		} else if (deviceType === 'tablet') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 36 }}
				>
					<path d="M0 16H16V0H11.4286V11.4286H0V16Z" fill="#2A6173" />
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 42 }}
				>
					<path d="M0 18H18V0H12.8571V12.8571H0V18Z" fill="#2A6173" />
				</svg>
			);
		}
	};

	return (
		<div
			id="component-product-section"
			style={{
				backgroundImage: `url(${bgTeam})`,
				color: '#FAFAFA',
				height: 'auto',
				paddingLeft: deviceType !== 'mobile' ? '10%' : '5%',
				paddingRight: deviceType !== 'mobile' ? '10%' : '5%',
				...padding(),
				alignItems: 'center',
			}}
		>
			<div>
				<p
					style={{
						...fontTitle(),
						fontWeight: '800',
						width: '100%',
						alignItems: 'left',
						marginBottom: deviceType === 'mobile' ? 8 : 11,
						marginTop: '0px',
					}}
				>
					{detailTop()}
					PRODUCT BUILD
					{detailBottom()}
				</p>
				<p
					style={{
						color: '#D4D4D8',
						...fontSubtitle(),
						fontWeight: 500,
						lineHeight: '140%',
						alignItems: 'left',
						width: deviceType === 'desktop' ? '60%' : '100%',
						margin: 0,
					}}
				>
					Nossa{' '}
					<strong style={{ color: '#FAFAFA' }}>Metodologia Exclusiva</strong>,
					impulsiona sua estratégia, focando no cliente e na agilidade. Veja o
					que nossos profissionais dizem sobre a metodologia:
				</p>
				<div
					style={{
						borderBottom: '1px solid #3F3F46',
						marginTop: deviceType === 'desktop' ? 128 : 48,
						marginBottom: 32,
					}}
				></div>
				<div
					style={{
						textAlign: 'center',
						fontStyle: 'italic',
						fontWeight: 500,
					}}
				>
					<Slider {...settings}>
						<div className="card">
							<div
								style={{
									margin: 'auto',
									width: deviceType === 'mobile' ? '90%' : '60%',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<img
									src={UpAsp}
									height={deviceType === 'desktop' ? 24 : 14}
									style={{
										verticalAlign: 'top',
										marginTop: '2px',
										alignItems: 'left',
									}}
									alt="icon detail left"
								/>
								<p
									style={{
										...fontParagraph(),
										padding: deviceType === 'desktop' ? '0px 16px' : '0px 8px',
									}}
								>
									Vai além do desenvolvimento de projetos. É uma jornada desde o
									conceito até o sucesso pós-entrega.
								</p>
								<div
									style={{
										flexDirection: 'column',
										justifyContent: 'flex-end',
										display: 'flex',
									}}
								>
									<img
										src={BottomAsp}
										height={deviceType === 'desktop' ? 24 : 14}
										style={{
											verticalAlign: 'top',
											marginTop: '2px',
											alignItems: 'left',
										}}
										alt="icon detail left"
									/>
								</div>
							</div>
						</div>
						<div className="card">
							<div
								style={{
									margin: 'auto',
									width: deviceType === 'mobile' ? '90%' : '60%',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<img
									src={UpAsp}
									height={deviceType === 'desktop' ? 24 : 14}
									style={{
										verticalAlign: 'top',
										marginTop: '2px',
										alignItems: 'left',
									}}
									alt="icon detail left"
								/>
								<p
									style={{
										...fontParagraph(),
										padding: deviceType === 'desktop' ? '0px 16px' : '0px 8px',
									}}
								>
									Inovamos, implementamos e monitoramos, garantindo soluções
									sustentáveis e altamente eficazes.
								</p>
								<div
									style={{
										flexDirection: 'column',
										justifyContent: 'flex-end',
										display: 'flex',
									}}
								>
									<img
										src={BottomAsp}
										height={deviceType === 'desktop' ? 24 : 14}
										style={{
											verticalAlign: 'top',
											marginTop: '2px',
											alignItems: 'left',
										}}
										alt="icon detail left"
									/>
								</div>
							</div>
						</div>
						<div className="card">
							<div
								style={{
									margin: 'auto',
									width: deviceType === 'mobile' ? '90%' : '60%',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<img
									src={UpAsp}
									height={deviceType === 'desktop' ? 24 : 14}
									style={{
										verticalAlign: 'top',
										marginTop: '2px',
										alignItems: 'left',
									}}
									alt="icon detail left"
								/>
								<p
									style={{
										...fontParagraph(),
										padding: deviceType === 'desktop' ? '0px 16px' : '0px 8px',
									}}
								>
									Sucesso a longo prazo. Não termina com a entrega, mas com sua
									escalabilidade e prosperidade contínua.
								</p>
								<div
									style={{
										flexDirection: 'column',
										justifyContent: 'flex-end',
										display: 'flex',
									}}
								>
									<img
										src={BottomAsp}
										height={deviceType === 'desktop' ? 24 : 14}
										style={{
											verticalAlign: 'top',
											marginTop: '2px',
											alignItems: 'left',
										}}
										alt="icon detail left"
									/>
								</div>
							</div>
						</div>
					</Slider>
				</div>
				<div
					style={{
						borderBottom: '1px solid #3F3F46',
						marginTop: 32,
					}}
				></div>
			</div>
		</div>
	);
};

export default Carousel;
