import bgPartnership from '../../assets/images/bg-partnership.jpg';
import useGalaxyFold from '../../hooks/useGalaxyFold';

export default function ImageContainerMobile() {
	const marginGrid = '5%';

	const isGalaxyFold = useGalaxyFold();

	return (
		<div
			id="image-container-section"
			style={{
				height: '100%',
				minHeight: '66vh',
				maxHeight: '70vh',
				position: 'relative',
				width: '100%',
				overflow: 'hidden',
			}}
		>
			<img
				src={bgPartnership}
				alt="bglogo"
				style={{
					objectFit: 'cover',
					objectPosition: 'center',
					height: '100%',
					pointerEvents: 'none',
					maxWidth: '100%',
					maxHeight: '100vh',
					width: '100%',
				}}
				draggable={false}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
					justifyContent: 'end',
					height: '38%',
					width: '100%',
					zIndex: 0,
					position: 'absolute',
					top: '51%',
					right: 0,
					fontWeight: '500',
					margin: marginGrid,
					justifyItems: 'end',
					textAlign: 'right',
				}}
			>
				<p
					style={{
						fontSize: '16px',
						lineHeight: '22px',
						margin: '0px',
						color: '#D4D4D8',
						fontWeight: 500,
					}}
				>
					Transformamos
				</p>
				<p
					style={{
						fontSize: '36px',
						lineHeight: '50px',
						fontWeight: isGalaxyFold ? '700' : '900',
						margin: '0px',
						color: '#FAFAFA',
					}}
				>
					PARCERIAS
				</p>
				<p
					style={{
						fontSize: '16px',
						lineHeight: '22px',
						margin: '0px',
						color: '#D4D4D8',
						fontWeight: 500,
					}}
				>
					em progresso sustentável
				</p>
			</div>
		</div>
	);
}
