import './App.css';
import { useCallback, useEffect, useState } from 'react';
import { MdArrowUpward } from 'react-icons/md';
import useSetDevice from './hooks/useSetDevice';
import CookieBanner from './components/Cookies';
import Header from './components/Header';
import ImageContainerDesktop from './components/ImageContainerDesktop';
import ImageContainerMobile from './components/ImageContainerMobile';
import ContainerCards from './components/ContainerCards';
import ComponentCards from './components/ComponentCards';
import BackgroundSolutionsDesktop from './components/BackgroundSolutionsDesktop';
import BackgroundSolutionsMobile from './components/BackgroundSolutionsMobile';
import ContainerCarouselSolutions from './components/ContainerCarouselSolutions';
import ComponentProduct from './components/ComponentProduct';
import FormDesktop from './components/FormDesktop';
import FormMobile from './components/FormMobile';
import Footer from './components/Footer';

function App() {
	const deviceType = useSetDevice();

	const [showButton, setShowButton] = useState(false);

	const [activeComponent, setActiveComponent] = useState<string | null>(null);

	const handleScroll = () => {
		const headerSection = document.getElementById('header-section');
		const imageContainerSection = document.getElementById(
			'image-container-section'
		);
		const containerCardsSection = document.getElementById(
			'container-cards-section'
		);
		const componentCardsSection = document.getElementById(
			'component-cards-section'
		);
		const backgroundSolutionsSection = document.getElementById(
			'background-solutions-section'
		);
		const containerCarouselSolutionsSection = document.getElementById(
			'container-carousel-solutions-section'
		);
		const componentProductSection = document.getElementById(
			'component-product-section'
		);
		const formSection = document.getElementById('form-section');
		const footerSection = document.getElementById('footer-section');

		const sections = [
			{ id: 'Header', ref: headerSection },
			{ id: 'ImageContainer', ref: imageContainerSection },
			{ id: 'ContainerCards', ref: containerCardsSection },
			{ id: 'ComponentCards', ref: componentCardsSection },
			{ id: 'BackgroundSolutions', ref: backgroundSolutionsSection },
			{
				id: 'ContainerCarouselSolutions',
				ref: containerCarouselSolutionsSection,
			},
			{ id: 'ComponentProduct', ref: componentProductSection },
			{ id: 'Form', ref: formSection },
			{ id: 'Footer', ref: footerSection },
		];

		const windowHeight = window.innerHeight;

		for (const section of sections) {
			if (section.ref) {
				const rect = section.ref.getBoundingClientRect();

				const tabletIsActive =
					rect.top <= windowHeight / 2.7 && rect.bottom >= windowHeight / 2.7;

				const desktopIsActive =
					rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2;

				if (tabletIsActive || desktopIsActive) {
					setShowButton(section.id === 'ImageContainer' ? false : true);
					setActiveComponent(section.id);
					return;
				}
			}
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		setShowButton(activeComponent !== null);
	}, [activeComponent]);

	return (
		<div
			className="App"
			style={{
				alignItems: 'center',
				width: '100%',
				height: '65vh',
			}}
		>
			<Header deviceType={deviceType} activeComponent={activeComponent} />
			{deviceType !== 'mobile' ? (
				<ImageContainerDesktop deviceType={deviceType} />
			) : (
				<ImageContainerMobile />
			)}
			<div
				style={{
					flexDirection: 'column',
					paddingLeft: deviceType !== 'mobile' ? '10%' : '5%',
					paddingRight: deviceType !== 'mobile' ? '10%' : '5%',
				}}
			></div>
			<div style={{ margin: '0px' }}>
				<ContainerCards deviceType={deviceType} />
				<ComponentCards deviceType={deviceType} />
				{deviceType !== 'mobile' ? (
					<BackgroundSolutionsDesktop deviceType={deviceType} />
				) : (
					<BackgroundSolutionsMobile />
				)}
				<ContainerCarouselSolutions deviceType={deviceType} />
				<ComponentProduct deviceType={deviceType} />
				<CookieBanner deviceType={deviceType} />
				{deviceType !== 'mobile' ? (
					<FormDesktop deviceType={deviceType} />
				) : (
					<FormMobile />
				)}
				<Footer />
			</div>

			{showButton && (
				<button
					style={{
						position: 'fixed',
						bottom: '32px',
						right: '20px',
						padding: '12px',
						backgroundColor: '#380F43',
						opacity: '0.90',
						border: 'none',
						borderRadius: '8px',
						cursor: 'pointer',
					}}
					onClick={() => {
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}}
				>
					<MdArrowUpward
						style={{
							fontSize: '24px',
							color: '#FAFAFA',
						}}
					/>
				</button>
			)}
		</div>
	);
}

export default App;
