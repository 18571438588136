import Carousel from '../Carousel';

export interface IContainerCarouselSolutions {
	deviceType: string;
}

export default function ContainerCarouselSolutions({
	deviceType,
}: IContainerCarouselSolutions) {
	const padding = () => {
		if (deviceType === 'mobile') {
			return {
				paddingTop: '48px',
				paddingBottom: '48px',
				border: '1px solid transparent',
			};
		} else if (deviceType === 'tablet') {
			return {
				paddingTop: '64px',
				paddingBottom: '64px',
				border: '1px solid transparent',
			};
		} else {
			return {
				paddingTop: '128px',
				paddingBottom: '128px',
				border: '1px solid transparent',
			};
		}
	};

	const fontTitle = () => {
		if (deviceType === 'mobile') {
			return { fontSize: '24px', lineHeight: '33px' };
		} else if (deviceType === 'tablet') {
			return { fontSize: '36px', lineHeight: '50px' };
		} else {
			return { fontSize: '42px', lineHeight: '58x' };
		}
	};

	const detailTop = () => {
		if (deviceType === 'mobile') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 1 }}
				>
					<path d="M12 0H0V12H3.42857V3.42857H12V0Z" fill="#2A6173" />
				</svg>
			);
		} else if (deviceType === 'tablet') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					style={{ verticalAlign: 'bottom', marginBottom: 32 }}
				>
					<path d="M16 0H0V16H4.57143V4.57143H16V0Z" fill="#2A6173" />
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					style={{ verticalAlign: 'middle', marginBottom: 45 }}
				>
					<path d="M18 0H0V18H5.14286V5.14286H18V0Z" fill="#2A6173" />
				</svg>
			);
		}
	};

	const detailBottom = () => {
		if (deviceType === 'mobile') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 22 }}
				>
					<path d="M0 12H12V0H8.57143V8.57143H0V12Z" fill="#2A6173" />
				</svg>
			);
		} else if (deviceType === 'tablet') {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 32 }}
				>
					<path d="M0 16H16V0H11.4286V11.4286H0V16Z" fill="#2A6173" />
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					style={{ verticalAlign: 'top', marginTop: 39 }}
				>
					<path d="M0 18H18V0H12.8571V12.8571H0V18Z" fill="#2A6173" />
				</svg>
			);
		}
	};

	return (
		<div
			id="container-carousel-solutions-section"
			style={{
				...padding(),
				backgroundColor: '#CECED1',
				opacity: '90%',
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				overflow: 'hidden',
				boxSizing: 'border-box',
				position: 'relative',
				paddingLeft: deviceType !== 'mobile' ? '5%' : '5%',
				paddingRight: deviceType !== 'mobile' ? '5%' : '5%',
			}}
		>
			<div className="border-transparent"></div>
			<h1
				style={{
					color: '#521262',
					fontWeight: 800,
					textTransform: 'uppercase',
					fontSize: fontTitle().fontSize,
					lineHeight: fontTitle().lineHeight,
					alignItems: 'flex-start',
					paddingBottom: 20,
					margin: 0,
				}}
			>
				{detailTop()}
				Nossas Soluções
				{detailBottom()}
			</h1>
			<Carousel />
		</div>
	);
}
