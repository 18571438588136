import React, { useState, useEffect } from 'react';

export interface IComponentProduct {
	deviceType: string;
}

const CookieBanner: React.FC<IComponentProduct> = ({ deviceType }) => {
	// Estilização e animação do botão
	const [isHovered, setIsHovered] = useState(false);

	const buttonStyle: React.CSSProperties = {
		backgroundColor: isHovered ? '#380F43' : '#521262',
		display: 'flex',
		justifyContent: 'center',
		outline: 'none',
		cursor: isHovered ? 'pointer' : 'default',
		overflow: 'hidden',
		minWidth: '220px',
		height: '49px',
		color: '#FAFAFA',
		borderRadius: '4px',
		border: 0,
		alignSelf: 'center',
		paddingLeft: '14px',
		paddingRight: '14px',
		paddingTop: '13px',
		paddingBottom: '12px',
		letterSpacing: '0.15px',
		fontSize: deviceType !== 'desktop' ? '16px' : '18px',
		lineHeight: '140%',
		fontWeight: 500,
	};

	const spanStyle: React.CSSProperties = {
		marginLeft: isHovered ? '0' : '14px',
		transition: 'margin-left 0.3s ease',
		zIndex: 1,
		width: '90%',
		padding: 0,
	};

	const arrowStyle: React.CSSProperties = {
		alignSelf: 'center',
		opacity: isHovered ? 1 : 0,
		transition: 'opacity 0.3s ease, right 0.3s ease',
		marginRight: isHovered ? '8px' : '0',
	};

	const [acceptedCookies, setAcceptedCookies] = useState<boolean>(
		!!localStorage.getItem('acceptedCookies')
	);

	const acceptCookies = () => {
		localStorage.setItem('acceptedCookies', 'true');
		setAcceptedCookies(true);
	};

	useEffect(() => {
		if (acceptedCookies) {
			return;
		}
	}, [acceptedCookies]);

	if (acceptedCookies) {
		return null;
	}

	return (
		<div
			className="cookie-banner"
			style={{
				display: 'flex',
				flexDirection: deviceType === 'mobile' ? 'column' : 'row',
				justifyContent: 'space-between',
				gap: deviceType === 'mobile' ? '16px' : '32px',
				alignItems: 'center',
				position: 'fixed',
				bottom: '0',
				left: '0',
				right: '0',
				padding: '32px 10%',
				backgroundColor: '#E4E4E7',
				zIndex: 4,
				textAlign: deviceType === 'mobile' ? 'center' : 'left',
				fontSize: deviceType !== 'desktop' ? '14px' : '16px',
				lineHeight: '140%',
				fontWeight: 400,
				letterSpacing: '0.1px',
			}}
		>
			<p
				style={{
					lineHeight: '140%',
				}}
			>
				Ao navegar nesse site, você aceita os cookies que utilizamos para
				melhorar a sua experiência. Conheça nossos
				<strong>
					<a
						style={{ color: 'black', textDecoration: 'none' }}
						href="/terms-of-use"
						target="_blank"
						rel="noreferrer"
					>
						{' '}
						Termos de Uso
					</a>
				</strong>{' '}
				e nossa
				<strong>
					<a
						style={{ color: 'black', textDecoration: 'none' }}
						href="/privacy-policy"
						target="_blank"
						rel="noreferrer"
					>
						{' '}
						Política de Privacidade
					</a>
				</strong>
				.
			</p>
			<button
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				style={{
					...buttonStyle,
				}}
				onClick={acceptCookies}
			>
				<span style={spanStyle}>Aceitar e continuar</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="13"
					height="18"
					viewBox="0 0 13 18"
					fill="none"
					style={arrowStyle}
				>
					<path
						d="M7.85 9L0.949997 2.1L3.05 0L12.05 9L3.05 18L0.949997 15.9L7.85 9Z"
						fill="#FAFAFA"
					/>
				</svg>
			</button>
		</div>
	);
};

export default CookieBanner;
