import React, { useCallback, useEffect, useState } from 'react';
import Spinner from '../Spinner';

interface IFormState {
	name: string;
	email: string;
	phone: string;
	interest: string;
	company: string;
	content: string;
}

export interface IComponentProduct {
	deviceType: string;
}

export default function FormDesktop({ deviceType }: IComponentProduct) {
	const [formState, setFormState] = useState<IFormState>({
		name: '',
		email: '',
		phone: '',
		company: '',
		interest: '',
		content: '',
	});

	const [csrfToken, setCsrfToken] = useState('');

	// useEffect(() => {
	// 	setCsrfToken(
	// 		document
	// 			.querySelector('meta[name="csrf-token"]')!
	// 			.getAttribute('content')!
	// 	);
	// }, []);

	useEffect(() => {
		const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
		if (csrfMetaTag) {
			setCsrfToken(csrfMetaTag.getAttribute('content') || '');
		}
	}, []);

	const [isInputFocused, setIsInputFocused] = useState({
		name: false,
		email: false,
		phone: false,
		company: false,
		interest: false,
		message: false,
	});

	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const handleSelectOption = (option: string) => {
		setFormState({ ...formState, interest: option });
		setIsDropdownOpen(false);
	};

	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
		},
		[]
	);

	const padding = () => {
		if (deviceType === 'tablet') {
			return { paddingTop: '64px', paddingBottom: '64px' };
		} else {
			return { paddingTop: '128px', paddingBottom: '128px' };
		}
	};

	// Select
	const options = [
		'Alocação',
		'Arquitetura de Software',
		'Assessment',
		'Consultoria',
		'Projetos',
		'Squad dedicada',
		'Trabalhe conosco',
		'Outros',
	];

	const [verifyInputName, setVerifyInputName] = useState(false);
	const [verifyInputEmail, setVerifyInputEmail] = useState(false);
	const [verifyInputPhone, setVerifyInputPhone] = useState(false);
	const [verifyInputCompany, setVerifyInputCompany] = useState(false);
	const [verifyInputInterest, setVerifyInputInterest] = useState(false);
	const [verifyInputMessage, setVerifyInputMessage] = useState(false);

	const [formattedPhone, setFormattedPhone] = useState<string>('');

	const formatPhone = (input: string) => {
		const number = input.replace(/\D/g, '');

		let formattedNumber = '';
		if (number.length >= 2) {
			formattedNumber += `(${number.substring(0, 2)}`;
		}
		if (number.length > 2) {
			formattedNumber += `) ${number.substring(2, 7)}`;
		}
		if (number.length > 7) {
			formattedNumber += `-${number.substring(7, 11)}`;
		}
		return formattedNumber.slice(0, 15);
	};

	const errors = {
		name: formState.name.length === 0 ? true : false,
		email:
			!/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(
				formState.email
			)
				? true
				: false,
		phone: !/^\(\d{2}\) \d{5}-\d{4}$/.test(formState.phone) ? true : false,
		company: formState.company.length === 0 ? true : false,
		interest: formState.interest.length === 0 ? true : false,
		message: formState.content.length < 10 ? true : false,
	};

	const [errorMessages, setErrorMessages] = useState({
		name: '',
		email: '',
		phone: '',
		company: '',
		interest: '',
		message: '',
	});

	const createErrorMessageEmail = () => {
		if (formState.email.length === 0) {
			return 'Campo obrigatório.';
		}
		if (
			!/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(
				formState.email
			)
		) {
			return 'E-mail inválido.';
		}
		return '';
	};

	const createErrorMessagePhone = () => {
		if (formState.phone.length === 0) {
			return 'Campo obrigatório.';
		}
		if (!/^\(\d{2}\) \d{5}-\d{4}$/.test(formState.phone)) {
			return 'Telefone inválido.';
		}
		return '';
	};

	const createErrorMessage = () => {
		if (formState.content.length === 0) {
			return 'Campo obrigatório.';
		}
		if (formState.content.length < 10) {
			return 'Mensagem deve ter no mínimo 10 caracteres.';
		}
		return '';
	};

	const [firstFeedbackMessage, setFirstFeedbackMessage] = useState('');
	const [secondFeedbackMessage, setSecondFeedbackMessage] = useState('');

	const [loading, setLoading] = useState(false);

	const handleClick = () => {
		setLoading(true);
		setFirstFeedbackMessage('');
		setSecondFeedbackMessage('');

		if (Object.values(errors).every((error) => !error)) {
			const formToSend = new FormData();

			formToSend.append('_token', csrfToken);
			formToSend.append('name', formState.name);
			formToSend.append('email', formState.email);
			formToSend.append('phone', formState.phone);
			formToSend.append('company', formState.company);
			formToSend.append('interest', formState.interest);
			formToSend.append('content', formState.content);

			fetch('/send-email', {
				method: 'POST',
				body: formToSend,
			})
				.then((response) => response.json())
				.then((data) => {
					setLoading(false);
					setFirstFeedbackMessage('Formulário enviado!');
					setSecondFeedbackMessage('Retornaremos o contato em breve.');

					setFormState({
						...formState,
						name: '',
						email: '',
						phone: '',
						company: '',
						interest: '',
						content: '',
					});
					setFormattedPhone('');
					setVerifyInputName(false);
					setVerifyInputEmail(false);
					setVerifyInputPhone(false);
					setVerifyInputCompany(false);
					setVerifyInputInterest(false);
					setVerifyInputMessage(false);
					errorMessages.name = '';
					errorMessages.email = '';
					errorMessages.phone = '';
					errorMessages.company = '';
					errorMessages.interest = '';
					errorMessages.message = '';
				})
				.catch((error) => {
					setLoading(false);
					setFirstFeedbackMessage('Falha ao enviar o formulário!');
					setSecondFeedbackMessage('Por favor, tente novamente.');
				});
		} else {
			setLoading(false);
			if (errors.name) {
				setVerifyInputName(true);
			}
			if (errors.email) {
				setVerifyInputEmail(true);
			}
			if (errors.phone) {
				setVerifyInputPhone(true);
			}
			if (errors.company) {
				setVerifyInputCompany(true);
			}
			if (errors.interest) {
				setVerifyInputInterest(true);
			}
			if (errors.message) {
				setVerifyInputMessage(true);
			}
			setErrorMessages({
				name: errors.name ? 'Campo obrigatório.' : '',
				email: createErrorMessageEmail(),
				phone: createErrorMessagePhone(),
				company: errors.company ? 'Campo obrigatório.' : '',
				interest: errors.interest ? 'Campo obrigatório.' : '',
				message: createErrorMessage(),
			});
		}
	};

	// Estilização e animação do botão
	const [isHovered, setIsHovered] = useState(false);

	const buttonStyle: React.CSSProperties = {
		backgroundColor: isHovered ? '#380F43' : '#521262',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
		cursor: isHovered ? 'pointer' : 'default',
		overflow: 'hidden',
		width: '220px',
		height: '49px',
		color: '#FAFAFA',
		borderRadius: '4px',
		border: 0,
		alignSelf: 'flex-end',
		paddingLeft: '16px',
		paddingRight: '16px',
		paddingTop: '12px',
		paddingBottom: '12px',
		letterSpacing: '0.15px',
		fontSize: '18px',
		lineHeight: '25px',
		fontWeight: 500,
		gap: '8px',
	};

	const spanStyle: React.CSSProperties = {
		paddingLeft: '24px',
		textAlign: 'center',
		marginLeft: isHovered ? '-10px' : '0',
		transition: 'margin-left 0.3s ease',
		zIndex: 1,
	};

	const arrowStyle: React.CSSProperties = {
		alignSelf: 'center',
		opacity: isHovered ? 1 : 0,
		transition: 'opacity 0.3s ease, right 0.3s ease',
	};

	return (
		<form
			target="/send-email"
			method="post"
			id="form-section"
			style={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: '#E4E4E7',
				paddingLeft: '10%',
				paddingRight: '10%',
				...padding(),
			}}
			onSubmit={handleSubmit}
			noValidate
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
					gap: deviceType === 'tablet' ? '48px' : '64px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: deviceType === 'tablet' ? '16px' : '24px',
					}}
				>
					<div>
						<h3
							style={{
								color: '#521262',
								margin: 0,
								paddingBottom: deviceType === 'tablet' ? '0px' : '6px',
								fontSize: deviceType === 'tablet' ? '36px' : '42px',
								lineHeight: deviceType === 'tablet' ? '50px' : '58px',
								fontWeight: 800,
								alignItems: 'flex-start',
							}}
						>
							{deviceType === 'tablet' ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									style={{ verticalAlign: 'bottom', marginBottom: 32 }}
								>
									<path d="M16 0H0V16H4.57143V4.57143H16V0Z" fill="#2A6173" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									style={{ verticalAlign: 'middle', marginBottom: 45 }}
								>
									<path d="M18 0H0V18H5.14286V5.14286H18V0Z" fill="#2A6173" />
								</svg>
							)}
							SAIBA MAIS
							{deviceType === 'tablet' ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									style={{ verticalAlign: 'top', marginTop: 32 }}
								>
									<path d="M0 16H16V0H11.4286V11.4286H0V16Z" fill="#2A6173" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									style={{ verticalAlign: 'top', marginTop: 39 }}
								>
									<path d="M0 18H18V0H12.8571V12.8571H0V18Z" fill="#2A6173" />
								</svg>
							)}
						</h3>
						<p
							style={{
								color: '#71717A',
								marginTop: deviceType === 'tablet' ? '5px' : 0,
								marginBottom: 0,
								fontSize: '20px',
								lineHeight: '28px',
								fontWeight: 400,
								paddingBottom: deviceType === 'tablet' ? '48px' : '24px',
							}}
						>
							Tire suas dúvidas e conecte-se conosco.
						</p>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							gap: deviceType === 'tablet' ? '16px' : '24px',
							alignItems: 'stretch',
							boxSizing: 'border-box',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: deviceType === 'tablet' ? '80%' : '100%',
								position: 'relative',
								gap: deviceType === 'tablet' ? '32px' : '34px',
								boxSizing: 'border-box',
							}}
						>
							<input
								tabIndex={1}
								type="text"
								name="name"
								id="name"
								placeholder="Nome *"
								required
								autoComplete="off"
								value={formState.name}
								style={{
									backgroundColor: 'transparent',
									color: '#18181B',
									fontSize: '18px',
									lineHeight: '18px',
									letterSpacing: '0.15px',
									borderRadius: '4px',
									border:
										verifyInputName && errors.name
											? '2px solid #EF4444'
											: isInputFocused.name
											? '2px solid #521262'
											: '1px solid #71717A',
									padding: '12px',
									width: deviceType === 'tablet' ? 'auto' : 'auto',
									alignSelf: 'stretch',
									outline: 'none',
								}}
								onChange={(e) => {
									setFormState({
										...formState,
										name: e.currentTarget?.value || '',
									});
								}}
								onMouseOver={(e) => {
									if (!errors.name || verifyInputName === false) {
										(e.target as HTMLInputElement).style.border =
											'2px solid #2A6173';
									} else {
										(e.target as HTMLInputElement).style.border =
											'2px solid #EF4444';
									}
								}}
								onMouseOut={(e) => {
									if (!errors.name || verifyInputName === false) {
										(e.target as HTMLInputElement).style.border =
											'1px solid #71717A';
									} else {
										(e.target as HTMLInputElement).style.border =
											'2px solid #EF4444';
									}
								}}
								onFocus={(e) => {
									setIsInputFocused({
										...isInputFocused,
										name: true,
									});
								}}
								onBlur={(e) => {
									setIsInputFocused({
										...isInputFocused,
										name: false,
									});
								}}
							/>
							<div
								style={{
									color: '#71717A',
									fontSize: '14px',
									position: 'absolute',
									marginTop: '50px',
								}}
							>
								{errors.name && errorMessages.name && (
									<span>{errorMessages.name}</span>
								)}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									gap: deviceType === 'tablet' ? '16px' : '24px',
									position: 'relative',
								}}
							>
								<input
									tabIndex={3}
									type="tel"
									name="phone"
									id="phone"
									placeholder="Telefone *"
									maxLength={15}
									required
									autoComplete="off"
									value={formattedPhone || formState.phone}
									style={{
										backgroundColor: 'transparent',
										color: '#18181B',
										height: '20px',
										fontSize: '18px',
										lineHeight: '18px',
										letterSpacing: '0.15px',
										borderRadius: '4px',
										border:
											verifyInputPhone && errors.phone
												? '2px solid #EF4444'
												: isInputFocused.phone
												? '2px solid #521262'
												: '1px solid #71717A',
										padding: '12px',
										alignSelf: 'stretch',
										outline: 'none',
										width: deviceType === 'tablet' ? '60%' : '90%',
									}}
									onChange={(e) => {
										const inputPhone = e.currentTarget?.value || '';
										setFormState({
											...formState,
											phone: inputPhone,
										});
										setFormattedPhone(formatPhone(inputPhone));
									}}
									onMouseOver={(e) => {
										if (!errors.phone || verifyInputPhone === false) {
											(e.target as HTMLInputElement).style.border =
												'2px solid #2A6173';
										} else {
											(e.target as HTMLInputElement).style.border =
												'2px solid #EF4444';
										}
									}}
									onMouseOut={(e) => {
										if (!errors.phone || verifyInputPhone === false) {
											(e.target as HTMLInputElement).style.border =
												'1px solid #71717A';
										} else {
											(e.target as HTMLInputElement).style.border =
												'2px solid #EF4444';
										}
									}}
									onFocus={(e) => {
										setIsInputFocused({
											...isInputFocused,
											phone: true,
										});
									}}
									onBlur={(e) => {
										setIsInputFocused({
											...isInputFocused,
											phone: false,
										});
									}}
								/>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										color: '#71717A',
										fontSize: '14px',
										position: 'absolute',
										width: deviceType === 'tablet' ? '60%' : '90%',
										marginTop: '50px',
									}}
								>
									{errors.phone && errorMessages.phone && (
										<span>{errorMessages.phone}</span>
									)}
								</div>
								<div
									style={{
										width: deviceType === 'tablet' ? '80%' : '100%',
										marginBottom: deviceType === 'tablet' ? '14px' : '12px',
									}}
								>
									<input
										tabIndex={4}
										type="text"
										name="company"
										id="company"
										placeholder="Empresa *"
										required
										autoComplete="off"
										value={formState.company}
										style={{
											backgroundColor: 'transparent',
											color: '#18181B',
											fontSize: '18px',
											lineHeight: '18px',
											letterSpacing: '0.15px',
											borderRadius: '4px',
											border:
												verifyInputCompany && errors.company
													? '2px solid #EF4444'
													: isInputFocused.company
													? '2px solid #521262'
													: '1px solid #71717A',
											padding: '12px',
											alignSelf: 'stretch',
											outline: 'none',
											boxSizing: 'border-box',
											width: deviceType === 'tablet' ? '100%' : '100%',
										}}
										onChange={(e) =>
											setFormState({
												...formState,
												company: e.currentTarget?.value || '',
											})
										}
										onMouseOver={(e) => {
											if (!errors.company || verifyInputCompany === false) {
												(e.target as HTMLInputElement).style.border =
													'2px solid #2A6173';
											} else {
												(e.target as HTMLInputElement).style.border =
													'2px solid #EF4444';
											}
										}}
										onMouseOut={(e) => {
											if (!errors.company || verifyInputCompany === false) {
												(e.target as HTMLInputElement).style.border =
													'1px solid #71717A';
											} else {
												(e.target as HTMLInputElement).style.border =
													'2px solid #EF4444';
											}
										}}
										onFocus={(e) => {
											setIsInputFocused({
												...isInputFocused,
												company: true,
											});
										}}
										onBlur={(e) => {
											setIsInputFocused({
												...isInputFocused,
												company: false,
											});
										}}
									/>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											color: '#71717A',
											fontSize: '14px',
											position: 'absolute',
											width: deviceType === 'tablet' ? '60%' : '90%',
											marginTop: '2px',
										}}
									>
										{errors.company && errorMessages.company && (
											<span>{errorMessages.company}</span>
										)}
									</div>
								</div>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '80%',
								gap: deviceType === 'tablet' ? '32px' : '34px',
								borderRadius: '4px',
								marginBottom: '0px',
							}}
						>
							<div
								style={{
									width: 'auto',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<input
									tabIndex={2}
									type="email"
									name="email"
									id="email"
									placeholder="E-mail *"
									required
									autoComplete="off"
									value={formState.email}
									style={{
										backgroundColor: 'transparent',
										color: '#18181B',
										fontSize: '18px',
										lineHeight: '18px',
										letterSpacing: '0.15px',
										borderRadius: '4px',
										border:
											verifyInputEmail && errors.email
												? '2px solid #EF4444'
												: isInputFocused.email
												? '2px solid #521262'
												: '1px solid #71717A',
										padding: '12px',
										width: deviceType === 'tablet' ? 'auto' : 'auto',
										alignSelf: 'stretch',
										outline: 'none',
										marginBottom: 0,
									}}
									onChange={(e) =>
										setFormState({
											...formState,
											email: e.currentTarget?.value || '',
										})
									}
									onMouseOver={(e) => {
										if (!errors.email || verifyInputEmail === false) {
											(e.target as HTMLInputElement).style.border =
												'2px solid #2A6173';
										} else {
											(e.target as HTMLInputElement).style.border =
												'2px solid #EF4444';
										}
									}}
									onMouseOut={(e) => {
										if (!errors.email || verifyInputEmail === false) {
											(e.target as HTMLInputElement).style.border =
												'1px solid #71717A';
										} else {
											(e.target as HTMLInputElement).style.border =
												'2px solid #EF4444';
										}
									}}
									onFocus={() => {
										setIsInputFocused({
											...isInputFocused,
											email: true,
										});
									}}
									onBlur={() => {
										setIsInputFocused({
											...isInputFocused,
											email: false,
										});
									}}
								/>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										color: '#71717A',
										fontSize: '14px',
										position: 'absolute',
										marginTop: '50px',
									}}
								>
									{errors.email && errorMessages.email && (
										<span>{errorMessages.email}</span>
									)}
								</div>
							</div>
							<div
								style={{
									position: 'relative',
									boxSizing: 'border-box',
									border:
										verifyInputInterest && errors.interest
											? '2px solid #EF4444'
											: isInputFocused.interest
											? '2px solid #521262'
											: '1px solid #71717A',
									borderRadius: '4px',
								}}
								onClick={() => setIsDropdownOpen(!isDropdownOpen)}
								onMouseOver={(e) => {
									if (!errors.interest || verifyInputInterest === false) {
										e.currentTarget.style.border = '2px solid #2A6173';
									} else {
										e.currentTarget.style.border = '2px solid #EF4444';
									}
								}}
								onMouseOut={(e) => {
									if (!errors.interest || verifyInputInterest === false) {
										e.currentTarget.style.border = '1px solid #71717A';
									} else {
										e.currentTarget.style.border = '2px solid #EF4444';
									}
								}}
								onFocus={(e) => {
									setIsInputFocused({
										...isInputFocused,
										interest: true,
									});
								}}
								onBlur={(e) => {
									setIsInputFocused({
										...isInputFocused,
										interest: false,
									});
								}}
								tabIndex={0}
							>
								<div
									tabIndex={5}
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										cursor: 'pointer',
										backgroundColor: 'transparent',
										color: formState.interest === '' ? '#71717A' : '#18181B',
										height: '44.8px',
										fontSize: '18px',
										lineHeight: '18px',
										letterSpacing: '0.15px',
										padding: '12px',
										alignSelf: 'stretch',
										outline: 'none',
										boxSizing: 'border-box',
										margin: 0,
										appearance: 'none',
										border: 'none',
									}}
								>
									<span
										style={{
											paddingTop: '4px',
											width: '100%',
											backgroundColor: 'transparent',
										}}
									>
										{formState.interest || 'Interesse *'}
									</span>
									{isDropdownOpen ? (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
										>
											<mask
												id="mask0_1438_424"
												maskUnits="userSpaceOnUse"
												x="0"
												y="0"
												width="24"
												height="24"
											>
												<rect width="24" height="24" fill="#D9D9D9" />
											</mask>
											<g mask="url(#mask0_1438_424)">
												<path
													d="M12 10.775L8.10005 14.675C7.91672 14.8583 7.68338 14.95 7.40005 14.95C7.11672 14.95 6.88338 14.8583 6.70005 14.675C6.51672 14.4917 6.42505 14.2583 6.42505 13.975C6.42505 13.6917 6.51672 13.4583 6.70005 13.275L11.3 8.675C11.4 8.575 11.5084 8.50417 11.625 8.4625C11.7417 8.42083 11.8667 8.4 12 8.4C12.1334 8.4 12.2584 8.42083 12.375 8.4625C12.4917 8.50417 12.6 8.575 12.7 8.675L17.3 13.275C17.4834 13.4583 17.575 13.6917 17.575 13.975C17.575 14.2583 17.4834 14.4917 17.3 14.675C17.1167 14.8583 16.8834 14.95 16.6 14.95C16.3167 14.95 16.0834 14.8583 15.9 14.675L12 10.775Z"
													fill={
														formState.interest === '' ? '#71717A' : '#18181B'
													}
												/>
											</g>
										</svg>
									) : (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
										>
											<mask
												id="mask0_1438_394"
												maskUnits="userSpaceOnUse"
												x="0"
												y="0"
												width="24"
												height="24"
											>
												<rect width="24" height="24" fill="#D9D9D9" />
											</mask>
											<g mask="url(#mask0_1438_394)">
												<path
													d="M12 14.9499C11.8667 14.9499 11.7417 14.9291 11.625 14.8874C11.5084 14.8457 11.4 14.7749 11.3 14.6749L6.70005 10.0749C6.51672 9.89157 6.42505 9.65824 6.42505 9.3749C6.42505 9.09157 6.51672 8.85824 6.70005 8.6749C6.88338 8.49157 7.11672 8.3999 7.40005 8.3999C7.68338 8.3999 7.91672 8.49157 8.10005 8.6749L12 12.5749L15.9 8.6749C16.0834 8.49157 16.3167 8.3999 16.6 8.3999C16.8834 8.3999 17.1167 8.49157 17.3 8.6749C17.4834 8.85824 17.575 9.09157 17.575 9.3749C17.575 9.65824 17.4834 9.89157 17.3 10.0749L12.7 14.6749C12.6 14.7749 12.4917 14.8457 12.375 14.8874C12.2584 14.9291 12.1334 14.9499 12 14.9499Z"
													fill={
														formState.interest === '' ? '#71717A' : '#18181B'
													}
												/>
											</g>
										</svg>
									)}
								</div>
								{isDropdownOpen && (
									<div
										style={{
											position: 'absolute',
											top: '100%',
											left: '0',
											width: '100%',
											overflowY: 'auto',
											height: '160px',
											backgroundColor: '#E4E4E7',
											zIndex: 2,
											textAlign: 'start',
											marginTop: '1px',
											border: '1px solid #71717A',
											borderRadius: '4px',
											padding: '0',
										}}
									>
										<ul
											style={{
												listStyleType: 'none',
												padding: '0',
												marginTop: '0',
												marginBottom: '0',
											}}
										>
											{options.map((option) => (
												<li
													key={option}
													style={{
														cursor: 'pointer',
														padding: '10px',
														color: '#71717A',
													}}
													onClick={() => handleSelectOption(option)}
													onMouseEnter={(e) =>
														(e.currentTarget.style.backgroundColor =
															'#DDC6DD') &&
														(e.currentTarget.style.color = '#18181B')
													}
													onMouseLeave={(e) =>
														(e.currentTarget.style.backgroundColor =
															'transparent') &&
														(e.currentTarget.style.color = '#71717A')
													}
												>
													{option}
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									color: '#71717A',
									fontSize: '14px',
									position: 'absolute',
									marginTop: deviceType === 'tablet' ? '131px' : '133px',
									backgroundColor: '#E4E4E7',
								}}
							>
								{errors.interest && errorMessages.interest && (
									<span>{errorMessages.interest}</span>
								)}
							</div>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<label htmlFor="message"></label>
						<textarea
							tabIndex={6}
							name="message"
							id="message"
							placeholder="Mensagem *"
							required
							autoComplete="off"
							value={formState.content}
							style={{
								backgroundColor: 'transparent',
								color: '#18181B',
								fontSize: '18px',
								lineHeight: '18px',
								borderRadius: '4px',
								border:
									verifyInputMessage && errors.message
										? '2px solid #EF4444'
										: isInputFocused.message
										? '2px solid #521262'
										: '1px solid #71717A',
								padding: '12px',
								boxSizing: 'border-box',
								outline: 'none',
								height: '153px',
								alignSelf: 'flex-start',
								width: '100%',
								fontFamily: 'Roboto, sans-serif',
							}}
							onChange={(e) =>
								setFormState({
									...formState,
									content: e.currentTarget?.value || '',
								})
							}
							onMouseOver={(e) => {
								if (!errors.message || verifyInputMessage === false) {
									(e.target as HTMLInputElement).style.border =
										'2px solid #2A6173';
								} else {
									(e.target as HTMLInputElement).style.border =
										'2px solid #EF4444';
								}
							}}
							onMouseOut={(e) => {
								if (!errors.message || verifyInputMessage === false) {
									(e.target as HTMLInputElement).style.border =
										'1px solid #71717A';
								} else {
									(e.target as HTMLInputElement).style.border =
										'2px solid #EF4444';
								}
							}}
							onFocus={(e) => {
								setIsInputFocused({
									...isInputFocused,
									message: true,
								});
							}}
							onBlur={(e) => {
								setIsInputFocused({
									...isInputFocused,
									message: false,
								});
							}}
						></textarea>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								color: '#71717A',
								fontSize: '14px',
								position: 'absolute',
								width: deviceType === 'tablet' ? '60%' : '90%',
								marginTop: '156px',
							}}
						>
							{errors.message && errorMessages.message && (
								<span>{errorMessages.message}</span>
							)}
						</div>
					</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginRight: 'auto',
							}}
						>
							<div
								style={{
									color: '#521262',
									fontSize: deviceType === 'tablet' ? '18px' : '20px',
									width: '100%',
									letterSpacing: '0.15px',
									lineHeight: '140%',
									fontWeight: 700,
									marginBottom: '0px',
								}}
							>
								{firstFeedbackMessage}
							</div>

							<div
								style={{
									color: '#71717A',
									fontSize: deviceType === 'tablet' ? '18px' : '20px',
									width: '100%',
									marginTop: '0px',
									letterSpacing: '0.15px',
									lineHeight: '140%',
								}}
							>
								{secondFeedbackMessage}
							</div>
						</div>
						<div
							tabIndex={7}
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<button
								type="submit"
								style={buttonStyle}
								onClick={handleClick}
								onMouseEnter={() => setIsHovered(true)}
								onMouseLeave={() => setIsHovered(false)}
							>
								{loading ? (
									<Spinner size={25} color="#FAFAFA" />
								) : (
									<>
										<span style={spanStyle}>Enviar</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="13"
											height="18"
											viewBox="0 0 13 18"
											fill="none"
											style={arrowStyle}
										>
											<path
												d="M7.85 9L0.949997 2.1L3.05 0L12.05 9L3.05 18L0.949997 15.9L7.85 9Z"
												fill="#FAFAFA"
											/>
										</svg>
									</>
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}
