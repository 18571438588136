import React from 'react';
import useSetDevice from '../../hooks/useSetDevice';
import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa';
import path from '../../assets/images/logo-path.svg';

const Footer: React.FC = () => {
	const deviceType = useSetDevice();

	const padding = () => {
		if (deviceType === 'mobile') {
			return { paddingTop: '24px', paddingBottom: '24px' };
		} else if (deviceType === 'tablet') {
			return { paddingTop: '64px', paddingBottom: '64px' };
		} else {
			return { paddingTop: '64px', paddingBottom: '64px' };
		}
	};

	return (
		<footer
			id="footer-section"
			className="footer"
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				color: '#FAFAFA',
				paddingLeft: deviceType !== 'mobile' ? '10%' : '5%',
				paddingRight: deviceType !== 'mobile' ? '10%' : '5%',
				...padding(),
				backgroundColor: '#18181B',
			}}
		>
			<div
				className="footer-container"
				style={{
					flexDirection: deviceType === 'mobile' ? 'column' : 'row',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div
					className="footer-logo"
					style={{
						flexDirection: deviceType === 'mobile' ? 'column' : 'row',
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						paddingBottom: '20px',
					}}
				>
					<img
						src={path}
						alt="Logo"
						style={{ height: deviceType === 'mobile' ? 20 : 30 }}
					/>
				</div>
				<div
					className="footer-social"
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						color: '#FAFAFA',
						fontSize: deviceType === 'mobile' ? 16 : 24,
					}}
				>
					<a
						style={{
							marginRight: '15px',
							color: '#FAFAFA',
						}}
						href="https://www.linkedin.com/company/pathbit"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaLinkedin />
					</a>
					<a
						style={{
							marginRight: '15px',
							color: '#FAFAFA',
						}}
						href="https://www.instagram.com/pathbit/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaInstagram />
					</a>
					<a
						style={{ color: '#FAFAFA' }}
						href="https://www.facebook.com/pathbitconsultoria"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaFacebook />
					</a>
				</div>
			</div>
			<div
				className="footer-copyright"
				style={{
					flexDirection: deviceType === 'mobile' ? 'column' : 'row',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<p style={{ fontSize: deviceType === 'mobile' ? 14 : 16 }}>
					&copy; Pathbit | Todos os direitos reservados.
				</p>
			</div>
		</footer>
	);
};

export default Footer;
